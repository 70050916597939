<template>
  <default-layout :navDrawerLinks="navDrawerLinks"></default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/Default'

export default {
  name: 'invoicing-layout',
  components: {
    'default-layout': DefaultLayout,
  },
  data: () => ({}),
  computed: {
    navDrawerLinks() {
      return [
        {
          url: '/invoicing',
          text: this.$t('ui.invoicing') + ' ' + this.$t('ui.home'),
          icon: 'home',
        },
        {
          url: '/invoicing/general_ledger_code',
          text: this.$t('generalLedgerCode.navTitle'),
          icon: 'description',
        },
        {
          url: '/invoicing/invoice',
          text: this.$t('invoice.navTitle'),
          icon: 'description',
        },
        {
          url: '/invoicing/xero',
          text: this.$t('xeroInvoice.navTitle'),
          icon: 'receipt',
        },
        {
          url: '/invoicing/jobs',
          text: this.$t('jobs.navTitle'),
          icon: 'description',
        },
        {
          url: '/invoicing/failed-jobs',
          text: this.$t('failedJobs.navTitle'),
          icon: 'description',
        },
      ]
    },
  },
  methods: {},
}
</script>
