import api from '@/boot/api'
import {
  makeDefaultGetters,
  makeDefaultMutations,
  propertyLoading,
  setProperties,
  setPropertiesLoading,
} from '@/store/shared'

const defaultState = () => ({
  propertiesLoading: {},
})

const properties = Object.keys(defaultState())

const defaultGetters = makeDefaultGetters(properties)

const defaultMutations = makeDefaultMutations(properties, defaultState())

const state = defaultState()

const getters = { ...defaultGetters, propertyLoading }

const actions = {
  async fetch({ dispatch }, payload = {}) {
    if (!payload.method) payload.method = 'GET'
    await dispatch('send', payload)
  },
  async send({ dispatch }, payload) {
    const { data } = await api(payload?.url, payload)
    dispatch('setProperties', data)
  },
  async setProperties(context, data) {
    setProperties(context, properties, data)
  },
  async update({ commit, dispatch }, { data, url }) {
    commit('setPropertiesLoading', { data, value: true })
    await dispatch('send', { data, method: 'PUT', url })
    commit('setPropertiesLoading', { data, value: false })
  },
}

const mutations = {
  ...defaultMutations,
  setPropertiesLoading,
}

const mod = {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
}

export default mod
