const routes = [
  {
    path: '/',
    redirect: '/dashboard',
  },
  {
    path: '/home',
    redirect: '/dashboard',
  },
  {
    // catch all missing pages
    path: '*',
    redirect: '/errors/404',
  },
]

export default routes
