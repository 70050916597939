<template>
  <div class="navbarIcons">
    <v-btn
      class="navbarIcon"
      @click="clicked(icon)"
      :to="icon.url"
      v-for="(icon, index) in icons"
      :key="'navbarIcon' + index"
      icon
    >
      <v-icon>{{ icon.icon }}</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'NavbarIcons',
  props: {
    icons: { type: Array, required: true },
  },
  methods: {
    clicked(icon) {
      this.$emit('clicked', icon)
    },
  },
}
</script>
