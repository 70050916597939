<template>
  <v-switch
    class="switchInput"
    :input-value="value"
    v-bind="{ ...$attrs, ...$props }"
    @change="onChange"
  />
</template>

<script>
export default {
  name: 'HubooSwitch',
  props: {
    autofocus: Boolean,
    color: String,
    'false-value': { type: [String, Number, Boolean], default: false },
    prefix: String,
    name: String,
    'true-value': { type: [String, Number, Boolean], default: true },
    value: Boolean,
  },
  data: () => ({}),
  computed: {},
  watch: {},
  methods: {
    onChange(e) {
      this.$emit('input', !!e)
    },
  },
}
</script>
