export default {
  errors: {
    301: 'You are not authorised to access this resource.',
    400: 'Sorry, the request was unsuccessful.',
    404: 'Sorry, we could not find the resource you were looking for.',
    500: 'The server could not process the request.',
    error: 'Error',
    nError: '{n} error',
    noUrl: 'No url',
    sorrySomethingWentWrong: 'Sorry, something went wrong',
  },
}
