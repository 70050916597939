<template>
  <v-app-bar app color="primary" dark :src="navImage">
    <v-app-bar-nav-icon class="alignSelfCenter" @click="$emit('toggle-nav')"></v-app-bar-nav-icon>
    <v-toolbar-title v-if="showTitle" class="toolbarTitle">
      <span class="alignSelfCenter">
        {{ $t('ui.billingApp') | title }}
      </span>
    </v-toolbar-title>
    <navbar-links :links="links"></navbar-links>
    <v-spacer></v-spacer>

    <div class="nav-icon version-select" v-if="isDefaultRoutePath">
      <price-list-version-select />
    </div>

    <div class="nav-icon" v-if="boxId">
      <v-btn text to="/billing">HubooBox: {{ boxId }}</v-btn>
    </div>

    <hub-logo mini dark />

    <navbar-icons :icons="icons" @clicked="$emit('icon-clicked', $event)"></navbar-icons>
  </v-app-bar>
</template>

<script>
import NavbarIcons from '@/components/nav/NavbarIcons'
import NavbarLinks from '@/components/nav/NavbarLinks'
import PriceListVersionSelect from './PriceListVersionSelect.vue'

export default {
  name: 'HubooNav',
  components: {
    NavbarIcons,
    NavbarLinks,
    PriceListVersionSelect,
  },
  props: {
    icons: Array,
    links: Array,
  },
  data: () => ({
    showTitle: true,
    priceListVersion: undefined,
  }),
  computed: {
    navImage() {
      return this.showNavImage ? 'https://picsum.photos/1920/1080?random&navbar' : ''
    },
    showNavbarLogo() {
      return this.$vuetify.breakpoint.mdAndUp
    },
    showNavImage() {
      return this.$env.SHOW_NAVBAR_IMAGE
    },
    boxId() {
      return this.$route.params.id
    },
    isDefaultRoutePath() {
      const paths = [
        '/billing/fulfilment_default_pricing',
        '/billing/default_packaging_pricing',
        '/billing/default_pick_rate_container_pricing',
        '/billing/default_subscription_pricing',
        '/billing/default_storage_pricing',
        '/billing/default_manual_pricing',
        '/billing/default_pick_rate_number_pricing',
      ]
      const route = this.$route.path
      return paths.includes(route)
    },
  },
}
</script>

<style lang="scss" scoped>
.headerLogo {
  display: block;
  margin-right: 0.5rem;
  max-width: 150px;
  text-align: right;
}
.toolbarTitle {
  align-self: center !important;
  margin: 0 1rem !important;
  padding: 0 !important;
}
.alignSelfCenter {
  align-self: center;
}

.nav-icon {
  margin-right: 1rem;
  display: flex;
  align-items: center;

  .v-btn {
    text-transform: none !important;
  }
}

.version-select {
  width: 14rem;
}
</style>
