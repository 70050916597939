import createCrudStore from '@/store/shared/storeFactory'

const failedJobs = createCrudStore('/failed-jobs/', {
  isHubooBoxRoute: false,
  failedJobs: { data: [] },
  state: {
    meta: {},
  },
  getters: {
    getMeta: state => state.meta,
  },
  actions: {},
})

export default failedJobs
