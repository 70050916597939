<template>
  <v-hover v-slot="{ hover }">
    <v-card :elevation="hover && !readonly ? 3 : 0">
      <v-list-item
        :two-line="twoLine"
        :three-line="threeLine"
        :class="{ clickable: clickable && !readonly, hovered: hover && !readonly }"
        :ripple="clickable && !readonly"
        :inactive="!clickable || readonly"
        class="listItem my-3"
        @click="clicked(item)"
        @keydown.enter.prevent="clicked(item)"
        tabindex="0"
      >
        <v-list-item-content class="listItemContent">
          <v-list-item-title
            class="listItemTitle text-h5"
            :class="titleColor ? titleColor + '--text' : ''"
            v-if="title"
            >{{ title }}</v-list-item-title
          >
          <v-list-item-subtitle class="listItemSubtitle text-subtitle-2" v-if="subtitle">{{
            subtitle
          }}</v-list-item-subtitle>
          <span
            v-if="getText"
            class="listItemText text-body-2"
            :class="[
              textColor ? textColor + '--text' : '',
              { hovered: hover && !readonly, isFalse, isNull, isTrue },
            ]"
            >{{ getText }}</span
          >
        </v-list-item-content>
      </v-list-item>
    </v-card>
  </v-hover>
</template>

<script>
import i18n from '@/boot/i18n'
import { lowerCase } from '@/utilities/filters'

export default {
  name: 'HubooListItem',
  props: {
    clickable: Boolean,
    item: { type: Object, required: true },
    falseText: { type: [Number, String], default: lowerCase(i18n.t('common.false')) },
    nullText: { type: [Number, String], default: lowerCase(i18n.t('common.null')) },
    readonly: Boolean,
    subtitle: [Number, String],
    text: [Number, String, Boolean],
    textColor: String,
    threeLine: Boolean,
    title: [Number, String],
    titleColor: String,
    trueText: { type: [Number, String], default: lowerCase(i18n.t('common.true')) },
    twoLine: Boolean,
  },
  data: () => ({}),
  computed: {
    getText() {
      let text = false
      if (this.isNull) text = this.nullText
      else if (this.isTrue) text = this.trueText
      else if (this.isFalse) text = this.falseText
      else text = this.text
      return text !== false ? new String(text) : false
    },
    isFalse() {
      return this.text === false
    },
    isNull() {
      return this.text === null || this.text === undefined
    },
    isTrue() {
      return this.text === true
    },
  },
  methods: {
    clicked(e) {
      if (this.clickable && !this.readonly) this.$emit('clicked', e)
    },
  },
}
</script>

<style lang="scss" scoped>
.fullHeight {
  height: 100%;
}
.clickable {
  cursor: pointer;
}
.listItemText {
  color: #537ceb;
}
.hovered {
  background-color: var(--v-primary-base);
  .listItem,
  .listItemSubtitle.v-list-item__subtitle,
  .listItemText,
  .listItemTitle {
    color: #ffffff;
  }
}
.isFalse {
  color: #f07128;
}
.isNull {
  color: #f0d228;
}
.isTrue {
  color: #09ca12;
}
</style>
