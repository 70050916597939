export default {
  common: {
    about: 'About',
    accept: 'Accept',
    back: 'Back',
    backToTop: 'Back to top',
    cancel: 'Cancel',
    close: 'Close',
    create: 'Create',
    created: 'Created',
    createdAt: 'Created at',
    currency: 'Currency',
    currencyNotAllowed: 'Currency not allowed',
    date: 'Date',
    dateRange: 'Date Range',
    defaultLower: 'default',
    description: 'Description',
    edit: 'Edit',
    edited: 'Edited',
    email: 'Email',
    enabled: 'enabled',
    error: 'Error',
    example: 'Example',
    exportToCsv: 'Export to CSV',
    exportToCsvFile: 'Export to CSV file',
    exportUrlMissing: 'Export URL missing',
    failedToRemoveTheX: 'Failed to remove the {x}',
    false: 'False',
    file: 'File',
    filters: 'Filters',
    full: 'Full',
    half: 'Half',
    home: 'Home',
    id: 'Id',
    importFromCsv: 'Import from CSV',
    loading: 'Loading',
    item: 'Item',
    name: 'Name',
    no: 'No',
    none: 'None',
    notes: 'Notes',
    null: 'Null',
    ok: 'OK',
    open: 'Open',
    password: 'Password',
    price: 'Price',
    protection: 'Huboo Extra',
    quantity: 'Quantity',
    raw: 'Raw',
    reset: 'Reset',
    save: 'Save',
    search: 'Search',
    settings: 'Settings',
    sortBy: 'Sort by',
    submit: 'Submit',
    successfullyRemovedTheX: 'Successfully removed the {x}',
    successfullyUpdatedX: 'Successfully updated {x}',
    successfulSnackMessage: 'Success!',
    failureSnackMessage: 'Something went wrong',
    summary: 'Client',
    surcharge: 'Surcharge',
    time: 'Time',
    true: 'True',
    total: 'Total',
    unknown: 'Unknown',
    updated: 'Updated',
    updatedAt: 'Updated at',
    user: 'User',
    username: 'Username',
    users: 'Users',
    value: 'Value',
    weight: 'Weight',
    yes: 'Yes',
  },
}
