<template>
  <v-card class="mx-auto text-center" color="primary" dark max-width="600">
    <v-card-text>
      <v-sheet color="rgba(0, 0, 0, .12)">
        <v-sparkline
          :value="value"
          color="rgba(255, 255, 255, .7)"
          height="100"
          padding="24"
          stroke-linecap="round"
          smooth
        >
          <template v-slot:label="item"> ${{ item.value }} </template>
        </v-sparkline>
      </v-sheet>
    </v-card-text>

    <v-card-text>
      <div class="display-1 font-weight-thin">
        Sales Last 24h
      </div>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions class="justify-center">
      <v-btn block text>
        Go to Report
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'HubooLineGraph',
  // Todo: Set this up so it can receive data and react to it, i.e., that it is a reusable component and not a fake piece of space filler
  data: () => ({
    value: [423, 446, 675, 510, 590, 610, 760],
  }),
}
</script>
