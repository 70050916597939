import axios from 'axios'
import { camelise, downloadDirectToBrowser, snakify } from '@/utilities/functions'
import { prefixUrl } from '@/utilities/helpers/urls'
import store from '@/boot/store'
import Vue from 'vue'
import Vuex from 'vuex'
import env from '@/boot/env'
Vue.use(Vuex)

export function addApiHeaders(headers) {
  const authType = store.getters['core/getAuthType']
  if (authType === 'passport') addPassportHeaders(headers)
  else throw 'authType not recognised'
  return headers
}

function addAuthHeaders(headers) {
  return headers
}

function addJsonHeaders(headers) {
  headers['Content-Type'] = 'application/json'
  headers['Accept'] = 'application/json'
  return headers
}

function addPassportHeaders(headers) {
  const accessToken = store.state.core.apiToken?.accessToken
  if (accessToken) headers['Authorization'] = 'Bearer ' + accessToken
  return addJsonHeaders(headers)
}

/**
 * A function for making api requests.
 *
 * @param {String} url Target api URL
 * @param {Object} options Optional parameters (optional)
 * @param {Boolean} options.auth Indicates if url requires authentication
 * @param {Mixed} options.data Request payload
 * @param {Object} options.headers Request headers (default: {})
 * @param {String} options.method Request method (default: 'GET')
 * @param {String} options.prefix URL prefix (default: 'api')
 * @param {Object} options.params Query parameters (default: {})
 * @param {Object} axiosOptions Additional options passed directly through to axios (default: {})
 *
 * @returns {Mixed} The result of the api request
 */
export default async function api(url, options, axiosOptions) {
  return request(url, options, axiosOptions)
}

function getBody(data, stringify) {
  if (!data) return ''
  if (typeof data === 'string') return data
  if (data instanceof FormData) return data
  const body = snakify(data)
  // TODO: reactivate this ternary when api is consistent, e.g., snake or camel, as currently params are expected to be camel, whereas body properties are expected to be snaked
  // const body = SNAKIFY_OUTGOING ? snakify(data) : data
  return stringify ? JSON.stringify(body) : body
}

function getUrl({ prefix, url }) {
  return prefixUrl(prefixUrl(url, prefix), env.API_ROOT_URL)
}

/**
 * Handle an ajax file download.
 *
 * @param {Object} payload payload
 * @param {String} payload.filename filename
 * @param {String} [payload.method=GET] HTTP method (default: GET)
 * @param {String} payload.url target url
 *
 * @returns {void}
 */
export async function downloadAjax(payload) {
  if (!payload) return Promise.reject('downloadAjax requires payload')
  const { filename, method = 'GET', url } = payload
  const headers = addApiHeaders({})
  headers['Content-Type'] = 'application/octet-stream'
  headers['Accept'] = '*'
  headers['Content-Disposition'] = 'attachment;filename=' + (filename || 'file.txt')
  return api('/', { method }, { responseType: 'blob', url })
    .then(response => {
      downloadDirectToBrowser({ filename, data: response.data })
      return response
    })
    .catch(error => {
      console.error(error)
      return error
    })
}

function handleUndefinedError() {
  console.error('api error (undefined error)')
  // assume for now 400 bad request
  store.dispatch('core/handleApiError', { status: 400 })
}

async function request(
  url,
  {
    auth,
    data,
    headers = {},
    method = 'GET',
    prefix = 'api',
    params = {},
    shouldCameliseResponse = true,
  } = {},
  axiosOptions = {}
) {
  if (!url) throw 'api needs a valid target url'
  store.commit('core/setApiEndpointsLoading', { method, url, value: true })
  const options = {
    data: getBody(data, !auth),
    headers: auth ? addAuthHeaders(headers) : addApiHeaders(headers),
    method,
    params: env.SNAKIFY_OUTGOING ? snakify(params) : params,
    url: getUrl({ prefix, url }),
    // pass through additional options last to override previous options where necessary
    ...axiosOptions,
  }
  return await axios(options)
    .then(response => {
      console.debug('response', response)
      if (options.responseType === 'blob') {
        return response
      } else {
        return env.CAMELISE_INCOMING && shouldCameliseResponse ? camelise(response) : response
      }
    })
    .catch(e => {
      const { response: r } = env.CAMELISE_INCOMING ? camelise(e) : e
      if (typeof r?.status === 'undefined') handleUndefinedError()
      else {
        console.error('api error', r)
        store.dispatch('core/handleApiError', r)
      }
    })
    .finally(() => {
      store.commit('core/setApiEndpointsLoading', { method, url, value: false })
    })
}
