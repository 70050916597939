<template>
  <hub-modal qa-name="edit create modal" @input="$emit('input', $event)" :value="value" persistent>
    <hub-modal-header fill-content>
      <template #title>
        <slot name="header" />
      </template>
    </hub-modal-header>

    <hub-modal-content>
      <v-form ref="form" :disabled="loading" :lazy-validation="lazyValidation" class="modal-form">
        <slot name="fields" v-bind="{ errorMessages }" />
      </v-form>
    </hub-modal-content>

    <template #actions>
      <slot name="form-actions" />
    </template>
  </hub-modal>
</template>

<script>
export default {
  name: 'ModalForm',
  props: {
    lazyValidation: Boolean,
    loading: Boolean,
    value: Boolean,
    fields: Object,
    activeField: String,
  },

  data: () => ({
    values: {},
    errors: {},
    activeFieldName: '',
  }),

  computed: {
    errorMessages() {
      if (!this.hasErrors) return
      return this.$store.getters['core/getErrors']?.[422].errors
    },

    hasErrors() {
      return this.$store.getters['core/hasErrors']
    },

    fieldKeys() {
      return Object.keys(this.fields)
    },
  },

  created() {
    this.resetErrors()
  },

  watch: {
    hasErrors(newErrors, oldErrors) {
      if (newErrors !== oldErrors) {
        this.$emit('error', this.errorMessages)
      }
    },

    value(newValue, oldValue) {
      if (newValue !== oldValue) this.$store.commit('core/resetErrors')

      if (newValue === false) {
        this.$emit('reset')
      }
    },

    activeField(newVal) {
      if (this.hasErrors) {
        this.removeError(newVal)
      }
    },
  },

  methods: {
    resetErrors() {
      this.$store.commit('core/resetErrors')
    },
    removeError(field) {
      this.$store.commit('core/removeError', field)
    },
  },
}
</script>

<style lang="scss">
.modal-form {
  .modal-form__field {
    margin-bottom: 1rem;
  }
}
</style>
