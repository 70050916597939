import audit from '@/i18n/en/audit'
import auth from '@/i18n/en/auth'
import billing from '@/i18n/en/billing'
import common from '@/i18n/en/common'
import docs from '@/i18n/en/docs'
import errors from '@/i18n/en/errors'
import fields from '@/i18n/en/fields'
import ui from '@/i18n/en/ui'
import validations from '@/i18n/en/validations'

export default {
  ...audit,
  ...auth,
  ...billing,
  ...common,
  ...docs,
  ...errors,
  ...fields,
  ...ui,
  ...validations,
}
