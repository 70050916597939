import failedOrdersRoute from '@/modules/FailedOrders/failedOrders.route'
import customSubscriptionPricingRoute from '@/modules/CustomSubscriptionPricing/customSubscriptionPricing.route'
import defaultSubscriptionPricingRoute from '@/modules/DefaultSubscriptionPricing/defaultSubscriptionPricing.route'
import customStoragePricingRoute from '@/modules/CustomStoragePricing/customStoragePricing.route'
import defaultStoragePricingRoute from '@/modules/DefaultStoragePricing/defaultStoragePricing.route'
import defaultPackagingPricingRoute from '@/modules/DefaultPackagingPricing/DefaultPackagingPricing.route'
import defaultManualPricingRoute from '@/modules/DefaultManualPricing/defaultManualPricing.route'
import auditRoute from '@/modules/Audit/Audit.route'
import defaultPickRateNumberPricingRoute from '@/modules/DefaultPickRateNumberPricing/defaultPickRateNumberPricing.route'
import defaultPickRateContainerPricingRoute from '@/modules/DefaultPickRateContainerPricing/defaultPickRateContainerPricing.route'
import fulfilmentDefaultPricingRoute from '@/modules/FulfilmentDefaultPricing/FulfilmentDefaultPricing.route'
import skuSpecificPricingRoute from '@/modules/SkuSpecificPricing/skuSpecificPricing.route'
import clientSummaryRoute from '@/modules/ClientSummary/ClientSummary.route'
import customFulfilmentPricingRoute from '@/modules/CustomFulfilmentPricing/FulfilmentPricing.route'

export default [
  failedOrdersRoute,
  customSubscriptionPricingRoute,
  defaultSubscriptionPricingRoute,
  customStoragePricingRoute,
  defaultStoragePricingRoute,
  defaultPackagingPricingRoute,
  defaultManualPricingRoute,
  auditRoute,
  defaultPickRateNumberPricingRoute,
  defaultPickRateContainerPricingRoute,
  fulfilmentDefaultPricingRoute,
  skuSpecificPricingRoute,
  clientSummaryRoute,
  customFulfilmentPricingRoute,

  {
    component: () =>
      import(
        /* webpackChunkName: "AdditionalPricing" */ '@/views/pages/billing/AdditionalPricing.vue'
      ),
    meta: { layout: 'huboo-box-layout' },
    name: 'AdditionalPricing', //custom packaging pricing
    path: '/billing/huboo_boxes/:id/additional_pricing',
  },
  {
    path: '/billing',
    redirect: '/billing/huboo_box_selection',
  },
  {
    component: () =>
      import(
        /* webpackChunkName: "FulfilmentBilling" */ '@/views/pages/billing/fulfilmentBilling/FulfilmentBilling.vue'
      ),
    meta: { layout: 'huboo-box-layout' },
    name: 'FulfilmentBilling',
    path: '/billing/huboo_boxes/:id/fulfilment_billing',
  },
  {
    component: () =>
      import(
        /* webpackChunkName: "ManualBilling" */ '@/views/pages/billing/manualBilling/ManualBilling.vue'
      ),
    meta: { layout: 'huboo-box-layout' },
    name: 'ManualBilling',
    path: '/billing/huboo_boxes/:id/manual_billing',
  },
  {
    component: () =>
      import(/* webpackChunkName: "StorageBilling" */ '@/views/pages/billing/StorageBilling.vue'),
    meta: { layout: 'huboo-box-layout' },
    name: 'StorageBilling',
    path: '/billing/huboo_boxes/:id/storage_billing',
  },
  {
    component: () =>
      import(
        /* webpackChunkName: "SubscriptionBilling" */ '@/views/pages/billing/SubscriptionBilling.vue'
      ),
    meta: { layout: 'huboo-box-layout' },
    name: 'SubscriptionBilling',
    path: '/billing/huboo_boxes/:id/subscription_billing',
  },
  {
    component: () =>
      import(
        /* webpackChunkName: "HubooBoxSelection" */ '@/views/pages/billing/HubooBoxSelection.vue'
      ),
    meta: { layout: 'billing-layout' },
    name: 'HubooBoxSelection',
    path: '/billing/huboo_box_selection',
  },
  {
    component: () =>
      import(
        /* webpackChunkName: "ManualPricing" */ '@/views/pages/billing/manualPricing/ManualPricing.vue'
      ),
    meta: { layout: 'huboo-box-layout' },
    name: 'ManualPricing',
    path: '/billing/huboo_boxes/:id/manual_pricing',
  },
  {
    component: () =>
      import(
        /* webpackChunkName: "PickRatePricing" */ '@/views/pages/billing/pickRatePricing/PickRatePricing.vue'
      ),
    meta: { layout: 'huboo-box-layout' },
    name: 'PickRatePricing',
    path: '/billing/huboo_boxes/:id/pick_rate_pricing',
  },
]
