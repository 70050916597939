export default [
  {
    component: () => import(/* webpackChunkName: "Dashboard" */ '@/views/pages/Dashboard.vue'),
    meta: { layout: 'dashboard-layout' },
    name: 'Dashboard',
    path: '/dashboard',
  },
  {
    component: () => import(/* webpackChunkName: "Invoicing" */ '@/views/pages/Invoicing.vue'),
    meta: { layout: 'invoicing-layout' },
    name: 'Invoicing',
    path: '/invoicing',
  },
  {
    component: () => import(/* webpackChunkName: "Invoicing" */ '@/views/pages/Login.vue'),
    meta: { layout: 'login-layout' },
    name: 'Login',
    path: '/login',
  },
]
