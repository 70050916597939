import store from './store'

export default function registerPendo() {
  const authUser = store.getters['core/authUser']
  if (authUser) {
    try {
      window.pendo.initialize({
        visitor: {
          id: authUser.id,
          name: authUser.name,
          email: authUser.email,
          isAdmin: authUser.is_admin,
        },
      })
    } catch (error) {
      console.log('Failed to initialize Pendo', error)
    }
  }
}
