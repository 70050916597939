<template>
  <v-app>
    <v-main>
      <huboo-errors></huboo-errors>
      <huboo-snack-stack></huboo-snack-stack>
      <router-view class="viewContainer" id="routerViewport"></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'login-layout',
  components: {},
  props: {},
  data: () => ({}),
  computed: {},
  created() {},
  methods: {},
}
</script>
