// app
import HubooDatePicker from '@/components/forms/DatePicker'
import HubooDateRangePicker from '@/components/pages/DateRangePicker'
import HubooErrors from '@/components/pages/Errors'
import HubooFilters from '@/components/forms/FiltersMenu'
import HubooForm from '@/components/forms/Form'
import ModalForm from '@/components/forms/ModalForm'
import HubooFullScreenDialog from '@/components/pages/FullScreenDialog'
import HubooLineGraph from '@/components/images/LineGraph'
import HubooList from '@/components/pages/List'
import HubooListItem from '@/components/pages/ListItem'
import HubooLoading from '@/components/pages/Loading'
import HubooLoadingScreen from '@/components/pages/LoadingScreen'
import HubooLogo from '@/components/images/HubooLogo'
import HubooMaskInput from '@/components/forms/MaskInput'
import HubooModal from '@/components/pages/Modal'
import HubooPage from '@/components/pages/Page'
import HubooPageHeading from '@/components/pages/PageHeading'
import HubooRadio from '@/components/forms/Radio'
import HubooSettingsDrawer from '@/components/nav/SettingsDrawer'
import HubooSnackbar from '@/components/pages/Snackbar'
import HubooSnackStack from '@/components/pages/SnackStack'
import HubooSwitch from '@/components/forms/Switch'
import HubooTable from '@/components/pages/Table'
import HubooUploader from '@/components/forms/Uploader'

export default {
  // app
  'huboo-date-picker': HubooDatePicker,
  'huboo-date-range-picker': HubooDateRangePicker,
  'huboo-errors': HubooErrors,
  'huboo-filters': HubooFilters,
  'huboo-form': HubooForm,
  'modal-form': ModalForm,
  'huboo-full-screen-dialog': HubooFullScreenDialog,
  'huboo-line-graph': HubooLineGraph,
  'huboo-list': HubooList,
  'huboo-list-item': HubooListItem,
  'huboo-loading': HubooLoading,
  'huboo-loading-screen': HubooLoadingScreen,
  'huboo-logo': HubooLogo,
  'huboo-modal': HubooModal,
  'huboo-mask-input': HubooMaskInput,
  'huboo-page': HubooPage,
  'huboo-page-heading': HubooPageHeading,
  'huboo-radio': HubooRadio,
  'huboo-settings-drawer': HubooSettingsDrawer,
  'huboo-snackbar': HubooSnackbar,
  'huboo-snack-stack': HubooSnackStack,
  'huboo-switch': HubooSwitch,
  'huboo-table': HubooTable,
  'huboo-uploader': HubooUploader,
}
