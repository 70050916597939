const defaultManualPricingRoute = {
  component: () =>
    import(
      /* webpackChunkName: "DefaultManualPricing" */ '@/modules/DefaultManualPricing/DefaultManualPricing.vue'
    ),
  meta: { layout: 'huboo-box-layout' },
  name: 'DefaultManualPricing',
  path: '/billing/default_manual_pricing',
}

export default defaultManualPricingRoute
