export default {
  billing: {
    accountProtection: 'Huboo Account Extra',
    accountProtectionTypeNotAllowed: 'Huboo Account Extra type not allowed',
    accountLevelProtection: 'Huboo Account Extra level',
    additionalPricePerKilo: 'Additional price per kilo',
    additionalPricing: 'Packaging pricing',
    billedQuantity: 'Billed quantity',
    billing: 'Billing',
    billingDate: 'Billing date',
    billingHeader: 'Billing header',
    billingHeaderInputId: 'Billing header input id',
    billingRegion: 'Billing Region',
    billingType: 'Billing type',
    billingTypeNotAllowed: 'Billing type not allowed',
    boxSelection: 'Box selection',
    carrierRate: 'Carrier rate',
    clientOrderId: 'Client order id',
    containerType: 'Container type',
    containerTypeCode: 'Container type code',
    containerStandardVolume: 'Container standard volume',
    container: 'Container',
    country: 'Country',
    createAdditionalPricing: 'Create packaging pricing',
    createFulfilmentDefaultPricing: 'Create default fulfilment pricing',
    createFulfilmentPricing: 'Create fulfilment pricing',
    createManualBilling: 'Create Manual billing',
    createManualPricing: 'Create manual pricing',
    createPickRatePricing: 'Create pick rate pricing',
    createSkuSpecificPricing: 'Create sku specific pricing',
    creditLimit: 'Credit limit',
    currency: 'Currency',
    defaultConfirmationModal: {
      title: 'Are you sure you want to delete this price line?',
      message: 'This action will permanently delete this price line',
      confirm: 'Confirm',
      cancel: 'Cancel',
    },
    defaultDescriptions: {
      insert: 'Insert',
      perPackageFee: 'Per package fee',
      packagingSmallLetter: 'Packaging for Small Letter',
      packagingLargeLetter: 'Packaging for Large letter',
      packagingSmallPacket: 'Packaging for Small Packet',
      packagingSmallParcel: 'Packaging for Small Parcel',
      packagingMediumParcel: 'Packaging for Medium Parcel',
      packagingLargeParcel: 'Packaging for Large Parcel',
      notAllowed: 'Description type not allowed',
    },
    defaultPricing: 'Default Pricing',
    destinationCountry: 'Destination country',
    destinationRegion: 'Destination region',
    destinationZone: 'Destination zone',
    emptyOriginRegionNotAllowed: 'Client must have at least one operating region',
    exportToCsv: 'Export to CSV',
    exportToCsvFile: 'Export to CSV file',
    exportUrlMissing: 'Export URL missing',
    failedToRemoveThePickRatePriceList: 'Failed to remove the pick rate price list',
    failedToRemoveTheSkuSpecificPriceList: 'Failed to remove the sku specific price list',
    failedToUploadCsv: 'An error occured while uploading the CSV, please try again shortly',
    firstPickFree: 'First pick free',
    fulfilmentBilling: 'Fulfilment billing',
    fulfilmentCost: 'Fulfilment cost',
    fulfilmentFee: 'Fulfilment fee',
    fulfilmentLineFee: 'Fulfilment line fee',
    fulfilmentDefaultPricing: {
      title: 'Default fulfilment pricing',
      navTitle: 'Fulfilment pricing',
    },
    fulfilmentPricing: 'Fulfilment pricing',
    fulfilmentPricingCsvUpload: 'Fulfilment pricing upload',
    hubooBox: 'Huboo box',
    hubooBoxSelection: 'Huboo box selection',
    hubooSku: 'Huboo sku',
    insertFee: 'Insert fee',
    invoiceAddress: 'Invoice address',
    invoiceEmail: 'Invoice email',
    invoiceName: 'Invoice name',
    invoiceNumber: 'Invoice number',
    invoicingPreference: 'Invoicing preference',
    itemFees: 'Item fees',
    itemRatePrice: 'Item rate price',
    itemsStored: 'Items stored',
    itemWeight: 'Item weight',
    manifestUpdatedAt: 'Manifest updated at',
    manualBilling: 'Manual billing',
    manualCustomPriceList: 'Manual custom price list',
    manualDefaultPriceList: 'Manual default price list',
    manualPricing: 'Manual pricing',
    multiItem: 'Multi item',
    multiItemDiscount: 'Multi item discount',
    noPricingFound: 'No pricing found',
    operatingRegions: 'Operating regions',
    ordersDispatched: 'Orders dispatched',
    originCountry: 'Origin country',
    originRegion: 'Origin region',
    packagingCost: 'Packaging cost',
    packagingFee: 'Packaging fee',
    packagingLineFee: 'Packaging line fee',
    perItemLimit: 'Per item limit',
    perPackageFee: 'Per package fee',
    pickNumber: 'Pick number',
    pickRate: 'Pick rate',
    pickRateCost: 'Pick rate cost',
    pickRateFee: 'Pick rate fee',
    pickRatePricing: 'Pick rate pricing',
    pickRateType: 'Pick rate type',
    pickRateTypeNotAllowed: 'Pick rate type not allowed',
    pleaseReviewFulfilmentPricing: 'Please review fulfilment pricing',
    pleaseSelectAContainerType: 'Please select a container type',
    priceList: 'Price list',
    priceListCode: 'Price list code',
    priceListCodeNotAllowed: 'Price list code type not allowed',
    pricing: 'Pricing',
    quantityCharge: 'Quantity charge',
    region: 'Region',
    salesValue: 'Sales value',
    shippingPostcode: 'Shipping postcode',
    shippingService: 'Shipping service',
    shippingServiceCode: 'Shipping service code',
    skuRatePrice: 'Sku rate price',
    skuSpecificPricing: 'Sku specific pricing',
    skuSpecificCharge: 'Sku specific charge',
    skuSpecificFee: 'Sku specific fee',
    storageBilling: 'Storage billing',
    storageDiscount: 'Storage discount',
    storageType: 'Storage Type',
    itemStorage: 'Item',
    legacyStorage: 'Legacy',
    replenStorage: 'Replen',
    storageTypeNotAllowed: 'Storage Type not allowed',
    subscriptionBilling: 'Subscription billing',
    subscriptionFee: 'Subscription fee',
    successfullyRemovedThePickRatePriceList: 'Successfully removed the pick rate price list',
    successfullyUploadedCsv:
      "Successfully uploaded CSV, you'll receive an email shortly with confirmation.",
  },
  billingDefaultPricing: {
    title: 'Default Packaging Pricing',
    fields: {
      region: 'Region',
      selectAll: 'Select All',
    },
  },
  defaultPackagingPricing: {
    navTitle: 'Packaging Pricing',
    tablePageTitle: 'Default Packaging Pricing',
    fields: {
      currency: 'Currency',
      description: 'Description',
      originRegion: 'Origin Region',
      priceListCode: 'Price List Code',
      price: 'Price',
      createdAt: 'Created At',
      updatedAt: 'Updated At',
    },
    createModal: {
      createButton: 'Create New Default Packaging Pricing',
      title: 'Create New Default Packaging Pricing',
    },
    editModal: {
      title: 'Edit Default Packaging Pricing',
    },
    confirmationModal: {
      title: 'Are you sure you want to delete this price line?',
      message: 'This action will permanently delete this price line',
      confirm: 'Confirm',
      cancel: 'Cancel',
    },
  },
  defaultPickRateContainerPricing: {
    title: 'Default Pick Rate Container Pricing',
    navTitle: 'Pick Rate Container Pricing',
    tablePageTitle: 'Default Pick Rate Container Pricing',
    fields: {
      containerId: 'Container Id',
      containerTypeCode: 'Container Type Code',
      originRegion: 'Origin Region',
      currency: 'Currency',
      itemRatePrice: 'Item Rate Price',
      skuRatePrice: 'Sku Rate Price',
      createdAt: 'Created At',
      updatedAt: 'Updated At',
    },
    createModal: {
      createButton: 'Create New Default Pick Rate Container Pricing',
      title: 'Create New Default Pick Rate Container Pricing',
    },
    editModal: {
      title: 'Edit Default Pick Rate Container Pricing',
    },
    confirmationModal: {
      title: 'Are you sure you want to delete this price line?',
      message: 'This action will permanently delete this price line',
      confirm: 'Confirm',
      cancel: 'Cancel',
    },
  },
  defaultStoragePricing: {
    navTitle: 'Storage Pricing',
    tablePageTitle: 'Default Storage Pricing',
    fields: {
      priceListCode: 'Price List Code',
      description: 'Description',
      originRegion: 'Origin Region',
      currency: 'Currency',
      price: 'Price',
      createdAt: 'Created At',
      updatedAt: 'Updated At',
    },
    createModal: {
      createButton: 'Create New Default Storage Pricing',
      title: 'Create New Default Storage Pricing',
    },
    editModal: {
      title: 'Edit Default Storage Pricing',
    },
    confirmationModal: {
      title: 'Are you sure you want to delete this price line?',
      message: 'This action will permanently delete this price line',
      confirm: 'Confirm',
      cancel: 'Cancel',
    },
  },
  defaultSubscriptionPricing: {
    navTitle: 'Subscription Pricing',
    tablePageTitle: 'Default Subscription Pricing',
    tableHeaders: {
      priceListCode: 'Price List Code',
      description: 'Description',
      numberOfShipments: 'No of Shipments',
      price: 'Price',
      createdAt: 'Created At',
      updatedAt: 'Updated At',
    },
    createModal: {
      createButton: 'Add New Default Subscription Pricing',
      title: 'Add New Default Subscription Pricing',
    },
    editModal: {
      title: 'Edit Default Subscription Pricing',
      fields: {
        priceListCode: 'Price List Code',
        description: 'Description',
        numberOfShipments: 'Number of Shipments',
        price: 'Price',
      },
    },
  },
  failedOrders: {
    failedOrders: 'Failed Orders',
    reprocessButton: 'Reprocess orders',
    reprocessWithButton: 'Reprocess with fallback',
    reprocessWithoutButton: 'Reprocess without fallback',
    failedOrdersCsvUpload: 'Failed orders upload',
    csvUploadInformationMessage: '',
    reprocessOrderModalTitle: 'Reprocessing of Failed Orders',
    reprocessOrderModalContent:
      'There are {totalNumber} orders that have failed to be billed. Among these orders, {fallback} have a fallback shipping service',
    reprocessWithoutContent: '{reprocessWithout} will run a normal reprocess of all failed orders.',
    reprocessWithContent:
      '{reprocessWith} will reprocess all failed orders using a fallback shipping service.',
    reprocessOrdersExplanation:
      'Successful reprocessed orders will be dispatched and will not reappear in the failed orders table. Any failed reprocessed orders will repopulate the table.',
    successMessage:
      'Failed orders are now being reprocessed in the background. Reprocessing: {reprocessed} orders. Orders skipped: {skipped}.',
    tableHeaders: {
      createdAt: 'Created At',
      exceptionType: 'Exception Type',
      headerInputId: 'Header Input Id',
      hubooBox: 'Huboo Box',
      message: 'Message',
      status: 'Status',
      shippingServiceCode: 'Shipping Service',
      destinationRegion: 'Destination Region',
      destinationZone: 'Destination Zone',
      originRegion: 'Origin Region',
      weight: 'Weight',
      currency: 'Currency',
      containerTypeCode: 'Container Type Code',
      district: 'District',
      shippingPostcode: 'Shipping Postcode',
      updatedAt: 'Updated At',
      client: {
        invoiceName: 'Client Name',
      },
    },
    filters: {
      exceptionType: 'Exception Type',
      hubooBox: 'Huboo Box',
      status: 'Status',
      selectAll: 'Select All',
      shippingCodeEmpty: 'Shipping Code Is Empty',
      fulfilmentPriceEmpty: 'Fulfilment Price Line Is Empty',
      clientConsignmentNS: 'MI Client Consignment Not Supported',
      toProcess: 'To Process',
      open: 'Open',
      reopened: 'Reopened',
      resolved: 'Resolved',
    },
  },
  fulfilmentPricing: {
    title: 'Fulfilment pricing',
    editTitle: 'Edit Fulfilment Pricing',
    createTitle: 'Create Fulfilment Pricing',
    delete: 'Delete',
    deleteAll: 'Delete All',
    confirmationModal: {
      title: 'Are you sure?',
      message:
        'This will delete all {priceLinesTotal} custom price(s) for Client {hubooBox}, reverting them to default pricing. Are you sure you want to continue?',
    },
  },
  skuSpecificPricing: {
    navTitle: 'Sku Specific Pricing',
    tablePageTitle: 'Sku Specific Pricing',
    fields: {
      hubooSku: 'Huboo sku',
      description: 'Description',
      price: 'Price',
      quantityCharge: 'Quantity charge',
      isExclusive: 'Will Exclude Other Charges',
      isStorageBillable: 'Bill for Storage',
      originRegion: 'Origin Region',
      createdAt: 'Created At',
      updatedAt: 'Updated At',
    },
    createModal: {
      createButton: 'Add New Sku Specific Pricing',
      title: 'Add New Sku Specific Pricing',
    },
    editModal: {
      title: 'Edit Sku Specific Pricing',
    },
  },
  storagePricing: {
    navTitle: 'Storage Pricing',
    tablePageTitle: 'Storage Pricing',
    fields: {
      priceListCode: 'Price List Code',
      description: 'Description',
      originRegion: 'Origin Region',
      price: 'Price',
      currency: 'Currency',
      createdAt: 'Created At',
      updatedAt: 'Updated At',
    },
    createModal: {
      createButton: 'Add New Storage Pricing',
      title: 'Add New Storage Pricing',
    },
    editModal: {
      title: 'Edit Storage Pricing',
    },
  },
  subscriptionPricing: {
    navTitle: 'Subscription Pricing',
    tablePageTitle: 'Subscription Pricing',
    tableHeaders: {
      priceListCode: 'Price List Code',
      description: 'Description',
      numberOfShipments: 'No of Shipments',
      price: 'Price',
      createdAt: 'Created At',
      updatedAt: 'Updated At',
    },
    createModal: {
      createButton: 'Add New Subscription Pricing',
      title: 'Add New Subscription Pricing',
    },
    editModal: {
      title: 'Edit Subscription Pricing',
      fields: {
        priceListCode: 'Price List Code',
        description: 'Description',
        numberOfShipments: 'Number of Shipments',
        price: 'Price',
      },
    },
  },
  defaultManualPricing: {
    navTitle: 'Manual Pricing',
    tablePageTitle: 'Default Manual Pricing',
    fields: {
      name: 'Name',
      price: 'Price',
      currency: 'Currency',
      originRegion: 'Origin Region',
      createdAt: 'Created At',
      updatedAt: 'Updated At',
    },
    createModal: {
      createButton: 'Add New Manual Pricing',
      title: 'Add New Default Manual Pricing',
    },
    editModal: {
      title: 'Edit Default Manual Pricing',
    },
  },
  defaultPickRateNumberPricing: {
    navTitle: 'Pick Rate Number Pricing',
    createModal: {
      createButton: 'Add New Pricing',
      title: 'Add New Default Pick Rate Number Pricing',
    },
    fields: {
      pickNumber: 'Pick Number',
      itemRatePrice: 'Price',
      currency: 'Currency',
      originRegion: 'Origin Region',
      updatedAt: 'Updated At',
    },
    editModal: {
      title: 'Edit Default Pick Rate Number Pricing',
    },
  },
  clientSummary: {
    editButton: 'Edit client details',
    clientDetailsSection: 'Client Summary',
    clientDetailsSubSection: 'Client Details',
    billingDetailsSubSection: 'Billing Details',
    pickingDetailsSubSection: 'Picking and Storage Details',
    invoiceName: 'Invoice Name',
    invoiceEmail: 'Invoice Email',
    invoicingPreference: 'Invoicing Preference',
    accountProtection: 'Account Extra Level',
    billingType: 'Billing Type',
    currency: 'Currency',
    billingRegion: 'Billing Region',
    operatingRegions: 'Operating Regions',
    pickRateType: 'Pick Rate Type',
    perItemLimit: 'Per Item Limit',
    firstPickFree: 'First Pick Free',
    multiItemDiscount: 'Multi Item Discount',
    shelfDiscount: 'Shelf Discount',
    palletDiscount: 'Pallet Discount',
    storageDiscount: 'Storage Discount',
    storageType: 'Storage Type',
    pageTitle: 'Client',
    editModal: {
      modalTitle: 'Edit Client Setup',
    },
    noBillingRegion: 'No billing region set',
    noOperatingRegion: 'No operating region set',
    priceListVersion: 'Price List Version',
    priceListVersionName: 'Version Name',
    priceListVersionCreatedAt: 'Date Created',
  },
}
