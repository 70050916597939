<template>
  <div class="loadingLayoutContainer"></div>
</template>

<script>
export default {
  name: 'loading-layout',
  components: {},
  props: {},
  data: () => ({}),
  computed: {},
  methods: {},
}
</script>
