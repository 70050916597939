import createCrudStore from '@/store/shared/storeFactory'
import api from '@/boot/api'

const skuSpecificPricing = createCrudStore('/sku-specific-price-list/', {
  isHubooBoxRoute: true,
  state: {
    exportUrl: '',
    meta: {},
  },
  getters: {
    getExportUrl: state => state.exportUrl,
    getMeta: state => state.meta,
  },
  actions: {
    async create({ dispatch }, payload = {}) {
      const res = await api('/sku-specific-price-list/', payload, {
        method: 'POST',
      })
      if (res?.status === 201) {
        dispatch('handleSuccess')
      } else {
        dispatch('handleFailure')
      }
      return res
    },
  },
})

export default skuSpecificPricing
