const customFulfilmentPricingRoute = {
  component: () =>
    import(
      /* webpackChunkName: "CustomFulfilmentPricing" */ '@/modules/CustomFulfilmentPricing/FulfilmentPricing.vue'
    ),
  meta: { layout: 'huboo-box-layout' },
  name: 'CustomFulfilmentPricing',
  path: '/billing/huboo_boxes/:id/fulfilment_pricing',
}

export default customFulfilmentPricingRoute
