import xeroInvoicingRoute from '@/modules/XeroInvoicing/xeroInvoicing.route'
import invoiceRoute from '@/modules/Invoice/invoice.route'
import invoiceSummaryRoute from '@/modules/Invoice/InvoiceSummary/InvoiceSummary.route'
import jobsRoute from '@/modules/Jobs/jobs.route'
import failedJobsRoute from '@/modules/FailedJobs/failedJobs.route'

export default [
  xeroInvoicingRoute,
  invoiceRoute,
  invoiceSummaryRoute,
  jobsRoute,
  failedJobsRoute,
  {
    component: () => import(/* webpackChunkName: "Invoicing" */ '@/views/pages/Invoicing.vue'),
    meta: { layout: 'invoicing-layout' },
    name: 'Invoicing',
    path: '/invoicing',
  },
]
