<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="local"
        append-icon="event"
        readonly
        single-line
        v-bind="$props"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker v-model="local" @change="handleChange" no-title scrollable>
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="menu = false">
        {{ $t('common.close') }}
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: 'HubooDatePicker',
  props: {
    errorMessages: Array,
    label: String,
    placeholder: String,
    value: String,
  },
  created() {
    this.setLocal()
  },
  data: () => {
    return {
      local: null,
      menu: false,
    }
  },
  watch: {
    local() {
      if (this.local !== this.value) this.$emit('input', this.local)
    },
    value() {
      this.setLocal()
    },
  },
  methods: {
    handleChange(v) {
      this.local = v
    },
    setLocal() {
      if (this.local !== this.value) this.local = this.value
    },
  },
}
</script>

<style lang="scss" scoped></style>
