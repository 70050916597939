<template>
  <v-container align-items="center" fluid :class="{ fullHeight }">
    <v-row justify="center" align="center" :class="{ fullHeight }">
      <v-col class="text-center mx-3">
        <slot></slot>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'HubooPage',
  props: {
    fullHeight: Boolean,
  },
}
</script>

<style lang="scss" scoped>
.fullHeight {
  height: 100%;
}
</style>
