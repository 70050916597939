<template>
  <default-layout :navDrawerLinks="navDrawerLinks"></default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/Default'

export default {
  name: 'billing-layout',
  components: {
    'default-layout': DefaultLayout,
  },
  data: () => ({}),
  computed: {
    navDrawerLinks() {
      return [
        {
          url: '/billing/huboo_box_selection',
          text: this.$t('billing.hubooBoxSelection'),
          icon: 'dashboard',
        },
        {
          url: '/billing/failed-orders',
          text: this.$t('failedOrders.failedOrders'),
          icon: 'error',
        },
      ]
    },
  },
  methods: {},
}
</script>
