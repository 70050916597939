<template>
  <div class="snackStackContainer">
    <v-snackbar-queue
      :items="items"
      :timeout="timeout"
      bottom
      left
      @remove="removeSnackbar"
    ></v-snackbar-queue>
  </div>
</template>

<script>
import { SNACKBAR_DEFAULTS } from '@/utilities/constants'

const { color, message, timeout } = SNACKBAR_DEFAULTS

export default {
  name: 'huboo-snack-stack',
  props: {
    timeout: { type: [Number, String], default: timeout },
  },
  data: () => ({}),
  computed: {
    items() {
      return this.snackStack.map(snackbar => ({ color, message, ...snackbar }))
    },
    snackStack() {
      return this.$store.getters['core/getSnackStack'] || []
    },
  },
  methods: {
    removeSnackbar(id) {
      this.$store.commit('core/removeSnackbar', id)
    },
  },
}
</script>
