<template>
  <v-list-group v-model="open" :prepend-icon="icon" :no-action="noAction" :sub-group="subGroup">
    <template v-slot:activator>
      <v-list-item-title>{{ text | title }}</v-list-item-title>
    </template>
    <slot></slot>
  </v-list-group>
</template>

<script>
export default {
  name: 'NavLinkGroup',
  components: {},
  props: {
    icon: String,
    noAction: Boolean,
    subGroup: Boolean,
    text: String,
  },
  data: () => ({
    open: false,
  }),
  methods: {},
}
</script>

<style lang="scss" scoped>
.v-application .primary--text {
  color: white !important;
}
</style>
