<template>
  <v-list :dense="dense" :max-width="maxWidth">
    <huboo-list-item
      v-for="(item, index) in items"
      :clickable="clickable"
      :item="item"
      :key="'hubooListItem' + index"
      v-bind="item"
      @clicked="$emit('clicked', $event)"
    >
    </huboo-list-item>
  </v-list>
</template>

<script>
export default {
  name: 'HubooList',
  props: {
    clickable: Boolean,
    dense: Boolean,
    items: { type: Array, required: true },
    maxWidth: [Number, String],
  },
}
</script>

<style lang="scss" scoped>
.fullHeight {
  height: 100%;
}
</style>
