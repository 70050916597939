const skuSpecificPricingRoute = {
  component: () =>
    import(
      /* webpackChunkName: "SkuSpecificPricing" */ '@/modules/SkuSpecificPricing/SkuSpecificPricing.vue'
    ),
  meta: { layout: 'huboo-box-layout' },
  name: 'SkuSpecificPricing',
  path: '/billing/huboo_boxes/:id/sku_specific_pricing',
}
export default skuSpecificPricingRoute
