import { pascalCase } from '@/utilities/filters'
import cloneDeep from 'lodash/cloneDeep'
import Vue from 'vue'

export function makeDefaultGetters(properties) {
  const defaultGetters = {}
  properties.forEach(p => {
    const g = 'get' + pascalCase(p)
    defaultGetters[g] = s => s[p]
  })
  return defaultGetters
}

export function makeDefaultMutations(properties, defaultState = {}) {
  const defaultMutations = {}
  properties.forEach(p => {
    const m = 'set' + pascalCase(p)
    const r = 're' + m
    defaultMutations[m] = (state, value) => Vue.set(state, p, value)
    defaultMutations[r] = state => Vue.set(state, p, cloneDeep(defaultState[p]))
  })
  return defaultMutations
}
