import config from '@/store/index'
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const store = new Vuex.Store({ ...config, plugins: [createPersistedState()] })

export default store
