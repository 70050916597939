export default [
  {
    component: () => import(/* webpackChunkName: "Dashboard" */ '@/views/pages/Dashboard.vue'),
    meta: { layout: 'dashboard-layout' },
    name: 'Dashboard',
    path: '/dashboard',
  },
  {
    component: () => import(/* webpackChunkName: "Invoicing" */ '@/views/pages/Login.vue'),
    meta: { layout: 'login-layout' },
    name: 'Login',
    path: '/login',
  },
  {
    component: () =>
      import(
        /* webpackChunkName: "XeroInvoicingCallback" */ '@/modules/XeroInvoicing/XeroInvoicing.vue'
      ),
    meta: { layout: 'invoicing-layout' },
    name: 'XeroInvoicingCallback',
    path: '/auth/xero/callback',
  },
]
