const defaultStoragePricingRoute = {
  component: () =>
    import(
      /* webpackChunkName: "DefaultStoragePricing" */ '@/modules/DefaultStoragePricing/DefaultStoragePricing.vue'
    ),
  meta: { layout: 'huboo-box-layout' },
  name: 'DefaultStoragePricing',
  path: '/billing/default_storage_pricing',
}

export default defaultStoragePricingRoute
