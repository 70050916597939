<template>
  <v-dialog ref="dialog" v-model="show" :persistent="!valid" :width="modalWidth">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :label="$t('common.dateRange')"
        outlined
        dense
        append-icon="event"
        v-model="dateRangeText"
        readonly
        hide-details
        single-line
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker v-model="dateRange" range scrollable>
      <v-btn text color="primary" @click="$store.commit('billing/resetDateRange')">
        {{ $t('common.reset') }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="show = false" :disabled="!valid">
        {{ $t('common.close') }}
      </v-btn>
    </v-date-picker>
  </v-dialog>
</template>

<script>
import { format, compareAsc } from 'date-fns'

export default {
  name: 'HubooDateRangePicker',
  data: () => ({
    show: false,
  }),
  props: {
    modalWidth: String,
  },
  computed: {
    dateRange: {
      get() {
        return this.$store.getters['billing/getDateRange']
      },
      set(r) {
        this.$store.commit('billing/setDateRange', this.sortDates(r))
      },
    },
    dateRangeText() {
      return this.generateText(this.dateRange)
    },
    rules() {
      return [v => Array.isArray(v), v => v.length === 2]
    },
    valid() {
      const { dateRange, rules } = this
      return rules[0](dateRange) && rules[1](dateRange)
    },
  },
  watch: {
    dateRange(n, o) {
      if (n && n !== o && this.dateRangeValid(n)) this.$emit('update', [n[0], n[1]])
    },
  },
  mounted() {
    if (!this.valid) this.$store.commit('billing/resetDateRange')
  },
  methods: {
    dateRangeValid(dr) {
      return dr && Array.isArray(dr) && dr.length === 2
    },
    generateText(r) {
      return r
        .map(d => {
          const p = Date.parse(d)
          if (!p) return d
          else return format(p, 'dd/MM/yyyy')
        })
        .join(' - ')
    },
    sortDates(r) {
      return r
        .map(d => {
          const p = Date.parse(d)
          if (!p) return Date.now()
          else return p
        })
        .sort(compareAsc)
        .map(d => format(d, 'yyyy-MM-dd'))
    },
  },
}
</script>

<style lang="scss" scoped></style>
