<template>
  <v-list nav dense>
    <v-list-item v-if="backLink" :to="backLink.url" link>
      <v-list-item-icon>
        <v-icon>{{ backLink.icon || 'navigate_before' }}</v-icon>
      </v-list-item-icon>
      <v-list-item-title>{{ backLink.text | title }}</v-list-item-title>
    </v-list-item>
    <v-divider v-if="backLink"></v-divider>
    <template v-for="(link, index) in links">
      <nav-link-group :key="'navLinkGroup' + index" v-if="link.links" v-bind="link">
        <nav-link
          :key="'navLink' + index + 'subLinkIndex' + subLinkIndex"
          v-for="(subLink, subLinkIndex) in link.links"
          v-bind="subLink"
        ></nav-link>
      </nav-link-group>
      <nav-link :key="'navLink' + index" v-bind="link" v-else></nav-link>
    </template>
  </v-list>
</template>

<script>
import NavLink from '@/components/nav/NavLink'
import NavLinkGroup from '@/components/nav/NavLinkGroup'

export default {
  name: 'NavLinkList',
  components: {
    'nav-link': NavLink,
    'nav-link-group': NavLinkGroup,
  },
  props: {
    backLink: Object,
    links: { type: Array, required: true },
  },
  methods: {},
}
</script>
