const defaultSubscriptionPricingRoute = {
  component: () =>
    import(
      /* webpackChunkName: "DefaultSubscriptionPricing" */ '@/modules/DefaultSubscriptionPricing/DefaultSubscriptionPricing.vue'
    ),
  meta: { layout: 'huboo-box-layout' },
  name: 'DefaultSubscriptionPricing',
  path: '/billing/default_subscription_pricing',
}

export default defaultSubscriptionPricingRoute
