<template>
  <div class="hubooTable">
    <HubConfirmationModal
      :value="confirmationModal"
      :cancelText="$t('billing.defaultConfirmationModal.cancel')"
      :confirmText="$t('billing.defaultConfirmationModal.confirm')"
      qaName="default-hub-confirmation"
      @cancel="modalCancel"
      @confirm="modalConfirm"
      danger
    >
      <template #title>
        {{ $t('billing.defaultConfirmationModal.title') }}
      </template>
      <template>
        {{ $t('billing.defaultConfirmationModal.message') }}
      </template>
    </HubConfirmationModal>
    <v-row class="align-center mb-1">
      <v-col cols="12" md="6">
        <div class="hubooTable__prepend" v-if="$slots.prepend">
          <slot name="prepend"></slot>
        </div>
      </v-col>

      <v-col cols="12" md="6">
        <div class="hubooTable__actions" v-if="$slots.actions">
          <slot name="actions"></slot>
        </div>
      </v-col>
    </v-row>

    <v-card class="elevation-0">
      <v-card-title>
        <v-row>
          <v-col :cols="!hideSearch ? 4 : 12" v-if="$slots.filter" class="py-0">
            <slot name="filter" />
          </v-col>
          <v-col v-if="!hideSearch" class="py-0">
            <v-text-field
              @input="handleSearchInput"
              @keyup.enter="handleSearchEnter"
              append-icon="search"
              hide-details
              :label="$t('common.search')"
              single-line
              v-model="search"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-title v-if="filters" class="pt-0">
        <v-form>
          <div class="d-flex align-center">
            <div class="text-left">
              <span>{{ $t('common.filters') }}:</span>
            </div>
            <div class="pl-2">
              <v-row class="align-center">
                <v-col>
                  <v-select
                    v-for="(filter, index) in filters"
                    :key="'hubooTableFilter' + index"
                    @change="handleFilterChange(filter, $event)"
                    :label="filter.label"
                    :disabled="loading"
                    :placeholder="filter.placeholder"
                    :items="filter.items"
                    v-model="filterValues[filter.name]"
                    class="pl-2"
                  ></v-select>
                </v-col>
                <v-col>
                  <div class="clearFiltersButton">
                    <v-btn color="warning" text @click="clearFiltersClicked">{{
                      $t('ui.clearFilters')
                    }}</v-btn>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
        </v-form>
      </v-card-title>
      <v-card-text>
        <v-data-table
          @click:row="handleRowClicked"
          @update:options="handleUpdateOptions"
          :class="{ clickable, 'elevation-1': true }"
          :disable-filtering="disableFiltering"
          :disable-pagination="disablePagination"
          :disable-sort="disableSort"
          :footer-props="footerProps"
          :headers="getHeaders"
          :header-props="headerProps"
          :hide-default-footer="disablePagination"
          :item-class="itemClass"
          :items-per-page="getItemsPerPage"
          :items="getItems"
          :loading="loading"
          :loading-text="loadingText"
          :options="options"
          :server-items-length="getServerItemsLength"
        >
          <template v-slot:body v-if="loading">
            <tbody>
              <tr class="v-data-table__empty-wrapper">
                <td colspan="7">Loading</td>
              </tr>
            </tbody>
          </template>
          <template v-slot:[`item.actions`]="{ item }" v-if="actions.length">
            <div class="actionsWrapper text-right">
              <v-btn
                :qaName="`${action.event}-action-button`"
                @click.stop="handleAction(action, item)"
                :class="action.class"
                :color="action.color"
                :dark="action.dark"
                :key="action.event"
                :loading="item.loading"
                :disabled="
                  (action.event === 'edit' && !item.editable) ||
                    (action.event === 'remove' && !item.deletable)
                "
                class="ma-1"
                fab
                x-small
                v-for="action in actions"
              >
                <v-icon>{{ action.icon }}</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import i18n from '@/boot/i18n'
import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'

export default {
  name: 'HubooTable',
  props: {
    clickable: Boolean,
    disableFiltering: Boolean,
    disablePagination: Boolean,
    disableSort: Boolean,
    edit: Boolean,
    filters: Array,
    groupBy: { default: () => [], type: Array },
    groupDesc: { default: () => [], type: Array },
    headers: { type: Array, required: true },
    hideSearch: Boolean,
    itemClass: String,
    itemsPerPage: Number,
    items: { type: Array, required: true },
    loadingText: { default: i18n.t('common.loading'), type: String },
    loading: Boolean,
    localPagination: Boolean,
    multiSort: Boolean,
    mustSort: Boolean,
    page: { default: 1, type: Number },
    remove: Boolean,
    searchOnInput: { default: true, type: Boolean },
    serverItemsLength: Number,
    sortBy: { default: () => [], type: Array },
    sortDesc: { default: () => [], type: Array },
  },
  data: () => ({
    filterValues: {},
    latestSearch: '',
    localItemsPerPage: 10,
    localOptions: {
      page: 1,
      sortBy: '',
      sortDesc: '',
      groupBy: '',
      groupDesc: '',
      multiSort: '',
      mustSort: '',
    },
    search: '',
    confirmationModal: false,
  }),
  computed: {
    actions() {
      const actions = []
      if (this.edit) actions.push({ color: 'success', icon: 'edit', event: 'edit' })
      if (this.remove)
        actions.push({
          color: 'primary',
          icon: 'delete_forever',
          event: 'remove',
        })
      return actions
    },
    defaultItemsPerPage() {
      return this.$store.getters['core/getTables']?.itemsPerPage
    },
    disableItemsPerPage() {
      return !this.localPagination || this.disablePagination
    },
    footerProps() {
      return {
        'items-per-page-options': Array(10, 25, 50, 100),
        'items-per-page-text': this.disablePagination ? '' : this.$t('ui.itemsPerPage'),
      }
    },
    getHeaders() {
      const headers = cloneDeep(this.headers)
      if (this.actions.length) headers.push({ text: '', value: 'actions', sortable: false })
      return headers
    },
    getItems() {
      return this.items.map(i => {
        Object.keys(i).forEach(k => {
          const header = this.getHeaders.find(h => h.value === k)
          if (!header) return
          if (header?.falseText && i[k] === false) i[k] = header.falseText
          else if (header?.trueText && i[k] === true) i[k] = header.trueText
        })
        return i
      })
    },
    getItemsPerPage() {
      if (this.disablePagination) return this.items.length
      else return this.localItemsPerPage
    },
    getServerItemsLength() {
      if (this.disablePagination) return this.items.length
      else if (this.localPagination) return undefined
      return this.serverItemsLength || 0
    },
    headerProps() {
      //vuetify uses material design icons
      const hp = { sortIcon: 'arrow_upward' }
      return hp
    },
    options() {
      return ['page', 'sortBy', 'sortDesc', 'groupBy', 'groupDesc', 'multiSort', 'mustSort'].reduce(
        (a, v) => {
          a[v] = this[v]
          return a
        },
        { itemsPerPage: this.itemsPerPage || this.defaultItemsPerPage }
      )
    },
  },
  watch: {
    itemsPerPage: function(v) {
      if (v) this.localItemsPerPage = v
    },
  },
  created() {
    this.resetFilterValues()
  },
  methods: {
    clearFiltersClicked() {
      this.resetFilterValues()
      this.handleFilterChange()
    },
    handleAction({ event }, item) {
      if (event === 'remove') {
        this.confirmationModal = true
      }
      this.$emit(event, item)
    },
    handleRowClicked(e) {
      this.$emit('row-clicked', e)
    },
    handleSearchInput(e) {
      const self = this
      const bounced = function() {
        if (e !== self.latestSearch && self.searchOnInput)
          self.handleSearch(self.search, 'search:input')
      }
      debounce(bounced, 600)
    },
    handleSearchEnter() {
      this.handleSearch(this.search, 'search:enter')
    },
    handleUpdateOptions(e) {
      if (this.localPagination) this.localOptions = e
      else this.$emit('update:options', e)
    },
    handleSearch(s, e) {
      this.$emit('search', s)
      if (e) this.$emit(e, s)
      this.latestSearch = s
    },
    handleFilterChange() {
      this.$emit('filter', this.filterValues)
    },
    resetFilterValues() {
      this.filterValues = {}
    },
    modalCancel() {
      this.confirmationModal = false
    },
    modalConfirm() {
      this.$emit('confirm-delete')
      this.confirmationModal = false
    },
  },
}
</script>

<style lang="scss">
.hubooTable {
  .clickable::v-deep tr {
    cursor: pointer;
  }
}

.hubooTable__prepend {
  justify-content: center;
  text-align: center;
  width: 100%;

  @media (min-width: 960px) {
    text-align: left;
    width: auto;
  }
}

.hubooTable__actions {
  display: flex;
  gap: 1rem;
  justify-content: center;
  text-align: right;
  width: 100%;

  @media (min-width: 960px) {
    justify-content: flex-end;
    width: auto;
  }
}
.hubooTable {
  .v-data-table-header th {
    white-space: nowrap;
  }
  .v-data-table-header__icon {
    color: var(--v-primary-base) !important;
    font-size: 14px !important;
  }

  .clickable tbody tr {
    cursor: pointer;
  }
}
</style>
