const fulfilmentDefaultPricingRoute = {
  component: () =>
    import(
      /* webpackChunkName: "FulfilmentDefaultPricing" */ '@/modules/FulfilmentDefaultPricing/FulfilmentDefaultPricing.vue'
    ),
  meta: { layout: 'huboo-box-layout' },
  name: 'FulfilmentDefaultPricing',
  path: '/billing/fulfilment_default_pricing',
}

export default fulfilmentDefaultPricingRoute
