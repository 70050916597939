<template>
  <div class="hubooModal">
    <v-dialog
      :full-screen="fullScreen"
      :value="value"
      @input="$emit('input', $event)"
      v-bind="$attrs"
      :max-width="maxWidth"
    >
      <template v-slot:activator="{ on, attrs }" v-if="activator">
        <v-btn color="primary" dark v-bind="attrs" v-on="on">{{
          activatorText || $t('common.open')
        }}</v-btn>
      </template>
      <slot></slot>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'HubooModal',
  props: {
    activator: Boolean,
    activatorText: String,
    fullScreen: Boolean,
    maxWidth: [Number, String],
    value: Boolean,
  },
  computed: {},
  methods: {},
}
</script>

<style lang="scss" scoped></style>
