import router from '@/boot/router'

export function goTo(url, route) {
  if (url && notCurrentRoute(url, route)) router.push(url)
}

export function notCurrentRoute(url, route) {
  if (route) return route.path !== url
  return !router.options.routes
    .filter(r => r.path === url || r.redirect === url)
    .map(r => r.redirect || r.path)
    .find(r => r === router.currentRoute?.path)
}

export function routeHasChanged(to, from) {
  if (!to) return false
  else if (!from) return true
  else return to.path !== from.path
}
