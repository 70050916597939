const customSubscriptionPricingRoute = {
  component: () =>
    import(
      /* webpackChunkName: "CustomSubscriptionPricing" */ '@/modules/CustomSubscriptionPricing/CustomSubscriptionPricing.vue'
    ),
  meta: { layout: 'huboo-box-layout' },
  name: 'CustomSubscriptionPricing',
  path: '/billing/huboo_boxes/:id/custom_subscription_pricing',
}

export default customSubscriptionPricingRoute
