import routes from '@/routes/index'
import store from '@/boot/store'
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  // TODO: check this still works with new deployment pipeline...
  base: process.env.BASE_URL,
  linkActiveClass: 'linkActive',
  linkExactActiveClass: 'linkActive',
  mode: 'history',
  routes,
  scrollBehavior() {
    // TODO: perhaps this should be more sophisticated - saving position on some routes might be a good idea...(could use meta property to declare if savedPosition should be used or not, for instance)
    // For reference, scrollBehavior params are (to, from, savedPosition)
    return {
      x: 0,
      y: 0,
    }
  },
})

router.beforeEach((...[to, , next]) => {
  // redirect unathenticated users to login screen
  if (to.name !== 'Login' && !store.state.core.apiToken) next({ name: 'Login' })
  else next()
})

router.beforeEach((...[to, , next]) => {
  // redirect to dashboard if params are missing or 'undefined'
  const { params } = to
  const paramMissing = p => !p || p === 'undefined'
  if (Object.keys(params).find(k => paramMissing(params[k]))) {
    console.error('param missing from url - redirecting to dashboard')
    next({ name: 'Dashboard' })
  } else next()
})

export default router
