<template>
  <v-progress-circular
    :color="color"
    :loading="loading"
    :indeterminate="indeterminate"
    :size="size"
  ></v-progress-circular>
</template>

<script>
export default {
  name: 'HubooLoading',
  props: {
    color: { default: 'primary', type: String },
    loading: { default: true, type: Boolean },
    indeterminate: { default: true, type: Boolean },
    size: [Number, String],
  },
}
</script>

<style lang="scss" scoped></style>
