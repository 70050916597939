import axios from 'axios'
import 'fontsource-roboto'
import Vue from 'vue'
import VueMask from 'v-mask'
import VueScrollTo from 'vue-scrollto'
import VuetifySnackbarQueue from 'vuetify-snackbar-queue'
import Env from '@/plugins/Env'
import filters from '@/utilities/filters'
import App from '@/App.vue'

// Boot app.
import vuetify from '@/boot/vuetify'
import i18n from '@/boot/i18n'
import router from '@/boot/router'
import store from '@/boot/store'
import '@/boot/uiLibrary'
import registerComponents from '@/boot/registerComponents'
import registerPendo from '@/boot/pendo'

// Vuetify style.
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Env)

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

const token = document.head.querySelector('meta[name="csrf-token"]')

if (token) {
  axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content
  window.csrfToken = token.content // TODO: test if this is necessary, and if not, remove
} else {
  // Uncomment if Laravel CSRF token required for app to function
  // console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token')
}

Object.keys(filters).forEach(f => Vue.filter(f, filters[f]))

Vue.use(VueMask)
Vue.use(VueScrollTo)
Vue.use(VuetifySnackbarQueue)
registerPendo()

// import other components
registerComponents()

Vue.config.productionTip = false

export default new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
