<template>
  <div id="root" v-cloak>
    <component :is="layout"></component>
  </div>
</template>

<script>
import {
  BillingLayout,
  DashboardLayout,
  DisabledLayout,
  HubooBoxLayout,
  InvoicingLayout,
  LoadingLayout,
  LoginLayout,
} from '@/layouts'
import { routeHasChanged } from '@/utilities/helpers/router'
import '@/assets/styles/app.scss'

export default {
  name: 'app',
  components: {
    'billing-layout': BillingLayout,
    'dashboard-layout': DashboardLayout,
    'disabled-layout': DisabledLayout,
    'huboo-box-layout': HubooBoxLayout,
    'invoicing-layout': InvoicingLayout,
    'loading-layout': LoadingLayout,
    'login-layout': LoginLayout,
  },
  props: {},
  data: () => ({}),
  computed: {
    layout() {
      // check for env variables - if missing, disable app by loading disabled layout
      if (!window.ENV) return 'disabled-layout'
      return this.$store.getters['core/getLayout']
    },
  },
  watch: {
    $route(to, from) {
      if (routeHasChanged(to, from)) this.$store.dispatch('core/handleRouteChange', { from, to })
    },
  },
  created() {
    this.$store.dispatch('core/onInitialLoad', {
      initialStateData: window.initialStateData || {},
    })
  },
  mounted() {},
  methods: {},
}
</script>

<style lang="scss" scoped>
[v-cloak] {
  display: none;
}
</style>
