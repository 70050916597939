<template>
  <div class="hubooFilters">
    <v-expansion-panels flat>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <p class="hubooFilters__header">
            <span class="text-h6 mr-4">
              {{ $t('ui.filtersMenu.title') }}
            </span>
            <hub-chip v-if="activeFilters != 0" name="active-filters" variant="active"
              >{{ activeFilters }} {{ $t('ui.filtersMenu.activeFiltersText') }}
            </hub-chip>
          </p>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="elevation-1 rounded-sm">
          <v-form>
            <v-row>
              <slot />
            </v-row>
          </v-form>
          <div class="hubooFilters__ctas">
            <hub-button
              name="clear-filters"
              class="hubooFilters__clear"
              variant="secondary"
              hub-icon="fa-close"
              icon-right
              @click.native.stop="onClearFilters"
            >
              {{ $t('ui.ctas.clearFilters.text') }}
            </hub-button>
            <hub-button
              v-show="!hideApplyButton"
              name="apply-filters"
              class="hubooFilters__apply"
              variant="default"
              @click.native.stop="onApplyFilters"
            >
              {{ $t('ui.ctas.applyFilters.text') }}
            </hub-button>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  name: 'HubooFilters',
  props: {
    activeFilters: { default: 0, type: Number },
    hideApplyButton: { type: Boolean },
  },
  data: () => {
    return {}
  },

  methods: {
    onClearFilters() {
      this.$emit('filtersClear')
    },
    onApplyFilters() {
      this.$emit('filtersApply')
    },
  },
}
</script>

<style lang="scss">
.hubooFilters {
  margin-bottom: 1.5rem;
  width: 100%;
  text-align: left;

  .v-expansion-panel-content {
    padding-bottom: 0.5rem;
    padding-top: 2rem;
  }

  .v-expansion-panel-header {
    padding: 0;
    max-width: 30%;
    border-bottom: 1px solid var(--v-background-darken1);
    margin-bottom: 1rem;
  }

  .v-expansion-panel-header:not(.v-expansion-panel-header--active) {
    margin-bottom: 0rem;
  }

  .v-text-field__details {
    display: none;
  }
}

.hubooFilters__header {
  margin-bottom: 0.5rem !important;
  display: flex;
}

.hubooFilters__ctas {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 2rem;

  .hub-button {
    flex-grow: 0 !important;
    margin-left: 1.5rem !important;
  }
}
</style>
