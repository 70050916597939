<template>
  <div class="page-heading text-md-left mb-4">
    <h1 v-if="heading || $slots.heading">
      <slot name="heading"></slot>
      {{ heading }}
    </h1>

    <h3 v-if="subheading || $slots.subheading">
      <slot name="subheading"></slot>
      {{ subheading }}
    </h3>

    <p v-if="text">
      {{ text }}
    </p>

    <div class="page-heading__actions" v-if="$slots.actions">
      <slot name="actions"></slot>
    </div>

    <div class="page-heading__extra-content" v-if="$slots.default">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HubooPageHeading',
  props: {
    heading: String,
    subheading: String,
    text: String,
  },
}
</script>

<style lang="scss" scoped>
.page-heading {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  h1 {
    flex: 1;
  }
}

.page-heading__actions {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 1rem;
  text-align: right;
  width: 100%;

  @media (min-width: 960px) {
    margin-top: 0;
    justify-content: flex-end;
    width: auto;
  }
}

.page-heading__extra-content {
  width: 100%;
}
</style>
