<template>
  <default-layout :navDrawerLinks="navDrawerLinks"></default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/Default'

export default {
  name: 'dashboard-layout',
  components: {
    'default-layout': DefaultLayout,
  },
  data: () => ({}),
  computed: {
    navDrawerLinks() {
      return [
        {
          url: '/',
          text: this.$t('ui.dashboard'),
          icon: 'dashboard',
        },
      ]
    },
  },
  methods: {},
}
</script>
