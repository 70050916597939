<template>
  <div class="hubooForm pa-5">
    <v-form
      ref="form"
      @input="$emit('validated', $event)"
      :disabled="loading"
      @submit.prevent="submit"
      :lazy-validation="lazyValidation"
    >
      <div v-if="title" class="titleContainer">
        <h3>{{ title }}</h3>
        <v-divider class="my-2" v-if="!hideDividers"></v-divider>
      </div>
      <div v-for="(field, index) in fields" :key="'formInput' + index">
        <v-alert v-if="field.formHelperText" dense outlined text type="info" class="my-0">{{
          field.formHelperText
        }}</v-alert>
        <component
          v-bind="field"
          @input="onInput(field, $event, index)"
          @keyup.enter.prevent="onEnter(field, $event, $el, index)"
          :value="values[field.name]"
          :is="getComponent(field)"
          :autofocus="shouldAutofocus(index)"
        ></component>
      </div>
      <div v-if="showSubmitButton" class="submitButtonContainer">
        <v-divider class="mb-2" v-if="!hideDividers"></v-divider>
        <v-btn color="primary" type="submit" :loading="loading">{{ $t('common.submit') }}</v-btn>
      </div>
      <v-row v-if="!showSubmitButton && loading">
        <v-col class="text-center">
          <huboo-loading></huboo-loading>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import Vue from 'vue'

export default {
  name: 'HubooForm',
  props: {
    fields: { type: Array, required: true },
    formHelperText: String,
    hideDividers: Boolean,
    lazyValidation: Boolean,
    loading: Boolean,
    showSubmit: { default: false, type: Boolean },
    title: String,
  },
  data: () => ({
    values: {},
  }),
  computed: {
    allFieldsTextBased() {
      const textBasedTypes = ['v-text-field', 'v-textarea']
      return !this.fields.find(f => textBasedTypes.includes(this.getComponent(f)))
    },
    errorMessages() {
      return !!this.$store.getters['core/getErrors']?.[422]
    },
    showSubmitButton() {
      return this.allFieldsTextBased || this.showSubmit
    },
  },
  created() {
    this.reset()
  },
  methods: {
    getComponent({ component }) {
      return component || 'v-text-field'
    },
    onEnter(f, e) {
      if (this.getComponent(f) === 'v-text-field') {
        if (f.validateOnBlur || f.blurOnEnter) e?.target?.blur()
      }
    },
    onInput(f, e) {
      this.$emit('input', { field: f, value: e })
      this.setValue(f, e)
      this.resetErrors()
    },
    reset() {
      this.resetErrors()
      this.setInitialValues()
    },
    resetErrors() {
      this.$store.commit('core/resetErrors')
    },
    setInitialValues() {
      this.fields.forEach(f => {
        this.setValue(f, f.value)
      })
    },
    setValue({ name, type }, value) {
      if (value && type === 'number') value = Number(value) // enforces number type
      Vue.set(this.values, name, value)
    },
    shouldAutofocus(index) {
      // TODO: likely wants some more sophisticated focus handling, but first field will do for now
      return index === 0
    },
    submit() {
      if (this.validate() && !this.loading && !this.errorMessages) {
        this.$emit('submit', { fields: this.fields, values: cloneDeep(this.values) })
      }
    },
    validate() {
      return this.$refs.form.validate()
    },
  },
}
</script>

<style lang="scss" scoped></style>
