import createCrudStore from '@/store/shared/storeFactory'
import api from '@/boot/api'

const jobs = createCrudStore('/jobs/', {
  isHubooBoxRoute: false,
  jobs: { data: [] },
  state: {
    meta: {},
    jobsStatus: [],
  },
  getters: {
    getMeta: state => state.meta,
    getJobsStatus: state => state.jobsStatus,
  },
  actions: {
    async fetchJobsStatus({ commit }) {
      try {
        const res = await api(`/jobs/status`, {
          method: 'GET',
        })
        commit('setJobsStatus', res.data.data)
        return true
      } catch (e) {
        console.error('Error in fetchJobsStatus:', e)
        return false
      }
    },
  },
  mutations: {
    setJobsStatus(state, payload) {
      const { attributes } = payload
      const { new: newJobs, pending, processing } = attributes

      // Get all unique job names from all breakdowns
      const allJobTypes = new Set([
        ...Object.keys(newJobs.breakdown),
        ...Object.keys(pending.breakdown),
        ...Object.keys(processing.breakdown)
      ])

      // Transform to required format
      const transformedStatus = Array.from(allJobTypes).map(jobType => {
        return {
          jobName: jobType,
          newStatus: newJobs.breakdown[jobType] || 0,
          pendingStatus: pending.breakdown[jobType] || 0,
          processingStatus: processing.breakdown[jobType] || 0
        }
      })

      state.jobsStatus = transformedStatus
    },
  },
})

export default jobs
