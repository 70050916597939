import { pascalCase } from '@/utilities/filters'
import i18n from '@/boot/i18n'
import cloneDeep from 'lodash/cloneDeep'
import swal from 'sweetalert'
import Vue from 'vue'

export const confirmRemovalOfResource = function(resource) {
  swal(i18n.t('resources.theXHasBeenDeleted', { x: resource.toLowerCase() }), {
    icon: 'success',
  })
}

export const getErrorMessage = function(status) {
  const key = 'errors.' + status
  return i18n.te(key) ? i18n.t(key) : i18n.t('common.error')
}

export const itemById = state => id => cloneDeep(state.items[id])

export const itemLoading = state => id => !!state.itemsLoading[id]

export const propertyLoading = state => property => !!state.propertiesLoading[property]

export const setItem = function(state, item) {
  if (item && item?.id) Vue.set(state.items, item.id, item)
  else throw { item, msg: 'setItem requires item with valid id' }
}

export const setItemLoading = function(state, { id, value }) {
  if (id) Vue.set(state.itemsLoading, id, value)
  else throw 'setItemLoading requires a valid id'
}

export const setProperties = function({ commit }, properties, data) {
  properties.forEach(p => {
    const mutation = 'set' + pascalCase(p)
    if (Object.prototype.hasOwnProperty.call(data, p)) commit(mutation, data[p])
  })
}

export const setPropertiesLoading = function(state, { data, value }) {
  Object.keys(data).forEach(p => {
    Vue.set(state.propertiesLoading, p, value)
  })
}

export const setState = (state, defaultState) => {
  const defaultKeys = Object.keys(defaultState)
  // unset non-default values
  Object.keys(state)
    .filter(k => !defaultKeys.includes(k))
    .forEach(k => {
      Vue.delete(state, k)
    })
  // set default values
  Object.keys(defaultState).forEach(k => {
    Vue.set(state, k, defaultState[k])
  })
}

export const sortByPosition = function(arr) {
  const newArr = cloneDeep(arr)
  newArr.sort((a, b) => {
    // if no positions, return 0 as not comparable - or if equal return 0
    if (!a.position || !b.position || a.position === b.position) return 0
    // return value based on position
    return a.position - b.position > 0 ? 1 : -1
  })
  return newArr
}
