<template>
  <v-dialog
    :value="value"
    class="fullScreenDialog"
    @input="handleInput"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-if="activator" v-slot:activator="{ on, attrs }">
      <v-btn color="primary" dark v-bind="attrs" v-on="on">
        {{ activatorText || $t('common.open') }}
      </v-btn>
    </template>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="close" class="closeButton">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title v-if="title">{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            v-for="(button, index) in toolbarButtons"
            :key="'modalToolbarButton' + index"
            dark
            text
            @click="handleToolbarButtonClicked(button)"
            class="toolbarButton"
          >
            {{ button.text }}
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <slot></slot>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'HubooModal',
  props: {
    activator: Boolean,
    activatorText: String,
    toolbarButtons: Array,
    title: String,
    value: Boolean,
  },
  data() {
    return {
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
    }
  },
  methods: {
    close() {
      this.$emit('input', false)
    },
    handleInput(e) {
      this.$emit('input', e)
    },
    handleToolbarButtonClicked(e) {
      this.$emit('toolbar-button-clicked', e)
    },
  },
}
</script>

<style lang="scss" scoped></style>
