import api from '@/boot/api'
import i18n from '@/boot/i18n'

const createCrudStore = (url, overrides = { isHubooBoxRoute: false }) => {
  function state() {
    return {
      data: [],
      form: {
        errors: {},
      },
      ...overrides.state,
    }
  }

  const getters = {
    getAll: state => state.data,

    getModuleUrl: (state, getters, rootState, rootGetters) => {
      return overrides.isHubooBoxRoute
        ? `huboo-box/${rootGetters['billing/getSelectedBox'].hubooBox}${url}`
        : url
    },

    getValidationErrors: (state, getters, rootState, rootGetters) => {
      const errors = rootGetters['core/getErrors']?.[422]?.message
      return errors
    },
    ...overrides.getters,
  }

  const actions = {
    async fetchCollection({ commit, getters }, payload) {
      const response = await api(getters.getModuleUrl, payload, {})
      if (response.data) {
        commit('setCollection', response.data)
      }
    },

    handleSuccess({ commit }, customMessage = '') {
      const message =
        i18n.t('common.successfulSnackMessage') + (customMessage ? ' ' + customMessage : '')
      commit('core/launchSnackbar', { color: 'success', message }, { root: true })
    },

    handleFailure({ commit, getters }) {
      const message = getters.getValidationErrors || i18n.t('common.failureSnackMessage')
      commit('core/launchSnackbar', { color: 'error', message }, { root: true })
    },

    handleCustomInfo({ commit }, customMessage) {
      const message = customMessage
      commit('core/launchSnackbar', { color: 'info', message }, { root: true })
    },

    async update({ dispatch }, payload = {}) {
      const res = await api(url + payload.id, payload, {
        method: 'PATCH',
      })
      if (res?.status === 200) dispatch('handleSuccess')
      return res
    },

    async create({ dispatch, getters }, payload = {}) {
      const res = await api(getters.getModuleUrl, payload, {
        method: 'POST',
      })
      if (res?.status === 201) {
        dispatch('handleSuccess')
      } else {
        dispatch('handleFailure')
      }
      return res
    },

    async delete({ commit, dispatch }, id) {
      const res = await api(url + id, {
        method: 'DELETE',
      })
      if (res?.status === 204) {
        commit('deleteById', id)
        dispatch('handleSuccess')
      } else dispatch('handleFailure')

      return res
    },

    ...overrides.actions,
  }

  const mutations = {
    setCollection(state, payload) {
      Object.assign(state, payload)
    },

    deleteById(state, id) {
      const index = state.data.findIndex(d => d.id == id)
      state.data.splice(index, 1)
    },

    ...overrides.mutations,
  }

  return {
    namespaced: true,
    actions,
    getters,
    mutations,
    state,
  }
}

export default createCrudStore
