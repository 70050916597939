<template>
  <div class="errorsContainer"></div>
</template>

<script>
export default {
  name: 'huboo-errors',
  props: {},
  data: () => ({}),
  computed: {
    errors() {
      return this.$store.getters['core/globalErrors']
    },
  },
  watch: {
    errors(e, eOld) {
      if (this.haveNewErrors(e, eOld)) this.launchErrorSnackbar(e)
    },
  },
  methods: {
    concatErrors(e) {
      if (['string', 'number'].includes(typeof e)) return String(e)
      else return e?.length ? e.join('\n') : ''
    },
    haveNewErrors(n, o) {
      return n?.length && n != o
    },
    launchErrorSnackbar(e) {
      const params = {
        color: 'error',
        message: this.concatErrors(e),
        // timeout: 100000, // TODO: refactor snackStack/snackbar and create custom snack stack component to remove reliance on VuetifySnackbarQueue plugin, which does not support custom timeout lengths for each instance
      }
      this.$store.commit('core/launchSnackbar', params)
    },
  },
}
</script>
