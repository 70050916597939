<template>
  <default-layout
    :backLink="backLink"
    :navDrawerLinks="navDrawerLinks"
    :loading="loading"
  ></default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/Default'
import { BILLING_TYPES } from '@/utilities/constants'

export default {
  name: 'huboo-box-layout',
  components: {
    'default-layout': DefaultLayout,
  },
  data: () => ({}),
  computed: {
    backLink() {
      return { url: '/billing/huboo_box_selection', text: this.$t('billing.hubooBoxSelection') }
    },
    billingLinks() {
      const prefix = this.urlPrefix
      return {
        text: this.$t('billing.billing'),
        icon: 'account_balance',
        noAction: true,
        links: [
          { url: prefix + 'fulfilment_billing', text: this.$t('billing.fulfilmentBilling') },
          { url: prefix + 'manual_billing', text: this.$t('billing.manualBilling') },
          { url: prefix + 'storage_billing', text: this.$t('billing.storageBilling') },
          { url: prefix + 'subscription_billing', text: this.$t('billing.subscriptionBilling') },
        ],
      }
    },
    boxId() {
      return this.$route.params.id
    },
    layoutActive() {
      return this.$route.meta?.layout === 'huboo-box-layout'
    },
    loading() {
      return this.$store.getters['core/apiEndpointIsLoading']({
        method: 'GET',
        url: 'huboo-box/' + this.boxId + '/client-billing-setups/view',
      })
    },
    navDrawerLinks() {
      const prefix = this.urlPrefix
      return [
        {
          url: prefix + 'summary',
          text: this.$t('common.summary'),
          icon: 'description',
        },
        this.billingLinks,
        this.pricingLinks,
        this.defaultPricingLinks,
        this.auditLink,
      ]
    },
    defaultPricingLinks() {
      const prefix = '/billing/'
      let links = [
        {
          url: prefix + 'fulfilment_default_pricing',
          text: this.$t('billing.fulfilmentDefaultPricing.navTitle'),
        },
        {
          url: prefix + 'default_packaging_pricing',
          text: this.$t('defaultPackagingPricing.navTitle'),
        },
        {
          url: prefix + 'default_subscription_pricing',
          text: this.$t('defaultSubscriptionPricing.navTitle'),
        },
        {
          url: prefix + 'default_storage_pricing',
          text: this.$t('defaultStoragePricing.navTitle'),
        },
        {
          url: prefix + 'default_manual_pricing',
          text: this.$t('defaultManualPricing.navTitle'),
        },
        {
          url: prefix + 'default_pick_rate_container_pricing',
          text: this.$t('defaultPickRateContainerPricing.navTitle'),
        },
        {
          url: prefix + 'default_pick_rate_number_pricing',
          text: this.$t('defaultPickRateNumberPricing.navTitle'),
        },
      ]
      return {
        text: this.$t('billing.defaultPricing'),
        icon: 'account_balance_wallet',
        noAction: true,
        links,
      }
    },
    pricingLinks() {
      const prefix = this.urlPrefix
      const pickRatePricingText = this.$t('billing.pickRatePricing')
      const showPickRatePricing = this.selectedBox?.billingType === BILLING_TYPES.PICK_RATE
      let links = [
        {
          url: prefix + 'fulfilment_pricing',
          text: this.$t('fulfilmentPricing.title'),
        },
        {
          url: prefix + 'pick_rate_pricing',
          text: pickRatePricingText,
        },
        {
          url: prefix + 'additional_pricing',
          text: this.$t('billing.additionalPricing'),
        },
        {
          url: prefix + 'sku_specific_pricing',
          text: this.$t('skuSpecificPricing.navTitle'),
        },
        {
          url: prefix + 'manual_pricing',
          text: this.$t('billing.manualPricing'),
        },
        {
          url: prefix + 'custom_subscription_pricing',
          text: this.$t('subscriptionPricing.navTitle'),
        },
        {
          url: prefix + 'custom_storage_pricing',
          text: this.$t('storagePricing.navTitle'),
        },
      ]
      if (!showPickRatePricing) {
        links = links.filter(l => l.text !== pickRatePricingText)
      }
      return {
        text: this.$t('billing.pricing'),
        icon: 'account_balance_wallet',
        noAction: true,
        links,
      }
    },
    auditLink() {
      return {
        text: this.$t('audit.audit'),
        icon: 'policy',
        url: this.urlPrefix + 'audit',
      }
    },
    isDefaultRoutePath() {
      const paths = [
        '/billing/fulfilment_default_pricing',
        '/billing/default_packaging_pricing',
        '/billing/default_pick_rate_container_pricing',
        '/billing/default_subscription_pricing',
        '/billing/default_storage_pricing',
        '/billing/default_manual_pricing',
        '/billing/default_pick_rate_number_pricing',
      ]
      const route = this.$route.path
      return paths.includes(route)
    },

    shouldFetchBox() {
      if (this.$route.name === 'ClientSummary') return true
      return this.layoutActive && !this.selectedBoxMatchesRouteId && !this.isDefaultRoutePath
    },
    selectedBox() {
      return this.$store.getters['billing/getSelectedBox']
    },
    selectedBoxMatchesRouteId() {
      return this.selectedBox?.hubooBox === this.boxId
    },
    urlPrefix() {
      if (!this.boxId) return '/billing/huboo_boxes/' + this.selectedBox.hubooBox + '/'
      return '/billing/huboo_boxes/' + this.boxId + '/'
    },
  },
  watch: {
    $route: function() {
      if (this.shouldFetchBox) this.fetchBox()
    },
  },
  created() {
    if (this.shouldFetchBox) this.fetchBox()
  },
  methods: {
    fetchBox() {
      this.$store.dispatch('billing/fetchClientBillingSetupByHubooBox', this.boxId)
    },
  },
}
</script>
