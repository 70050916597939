<template>
  <huboo-page :full-height="fullHeight">
    <h1>{{ $t('common.loading') }}</h1>
    <huboo-loading :loading="loading" class="mt-2" v-bind="$props" />
    <div class="logoContainer">
      <huboo-logo />
    </div>
  </huboo-page>
</template>

<script>
export default {
  components: {},
  name: 'HubooLoadingScreen',
  props: {
    color: { default: 'primary', type: String },
    fullHeight: { default: true, type: Boolean },
    loading: { default: true, type: Boolean },
    indeterminate: { default: true, type: Boolean },
    size: { default: 60, type: [Number, String] },
  },
}
</script>

<style lang="scss" scoped>
.logoContainer {
  margin: 2rem;
  display: flex;
  justify-content: center;
}
</style>
