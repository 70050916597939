export default {
  audit: {
    audit: 'Audit',
    auditable_id: 'Id',
    auditable_type: 'Type',
    event: 'Event',
    oldFieldValue: 'Old field value',
    newFieldValue: 'New field value',
    modifiedBy: 'Modified by',
    hubooBox: 'Huboo Box',
  },
}
