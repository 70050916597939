<template>
  <v-file-input
    :error-messages="errorMessages"
    :validate-on-blur="validateOnBlur"
    :accept="accept"
    :value="value"
    v-bind="$props"
    @change="onChange"
  />
</template>

<script>
export default {
  name: 'HubooUploader',
  props: {
    accept: Array,
    errorMessages: Array,
    label: String,
    rules: { default: () => [], type: Array },
    validateOnBlur: { default: false, type: Boolean },
    value: File,
  },
  data: () => ({}),
  watch: {},
  methods: {
    onChange(v) {
      this.$emit('input', v)
    },
  },
}
</script>

<style lang="scss" scoped></style>
