import createCrudStore from '@/store/shared/storeFactory'
import api from '@/boot/api'

export const InvoiceStatus = Object.freeze({
  NEW: 'new',
  DRAFT: 'draft',
  SYNCING: 'syncing',
  SYNCED: 'synced',
  FAILED: 'failed',
})

export const InvoiceAttachmentStatus = Object.freeze({
  NO_DATA: 'no_data',
  EXPORT_FAILED: 'export_failed',
  UPLOAD_FAILED: 'upload_failed',
  REQUESTED: 'requested',
  RUNNING: 'running',
  SUCCEEDED: 'succeeded',
  UPLOADED: 'uploaded',
})

const invoice = createCrudStore('/invoice/', {
  isHubooBoxRoute: false,
  state: {
    meta: {},
    selectedInvoice: null,
    defaultInvoiceStatuses: InvoiceStatus,
  },
  getters: {
    getMeta: state => state.meta,
    getSelectedInvoice: state => state.selectedInvoice,
    getInvoiceById: state => invoiceId => state.data.find(d => d.id === invoiceId),
    getStatuses: state => state.defaultInvoiceStatuses,
  },
  actions: {
    async create({ dispatch }, payload = {}) {
      const res = await api('/invoice/', payload, {
        method: 'POST',
      })
      if (res?.status === 201) {
        dispatch('handleSuccess')
      } else {
        dispatch('handleFailure')
      }
      return res
    },
    async syncInvoice({ dispatch, getters, commit }, payload = {}) {
      const res = await api(`/invoice/${payload.id}/xero/sync`, {
        method: 'POST',
      })
      const currentInvoice = getters.getSelectedInvoice
      commit('setSelectedInvoice', {
        ...currentInvoice,
        attributes: {
          ...currentInvoice.attributes,
          ...res.data,
        },
      })
      if (res?.status === 200) {
        dispatch('handleSuccess')
      } else {
        dispatch('handleFailure')
      }
    },
    async syncAllInvoices({ dispatch }) {
      const res = await api('/invoice/xero/syncAll', {
        method: 'POST',
      })
      if (res?.status === 200) {
        dispatch('handleCustomInfo', `Syncing ${res.data.length} invoices`)
      } else {
        dispatch('handleFailure')
      }
    },
    async fetchSingleInvoice({ commit }, invoiceId) {
      try {
        const res = await api(`/invoice/${invoiceId}`, {
          method: 'GET',
        })
        commit('setSelectedInvoice', res.data.data)
        return true
      } catch (e) {
        return false
      }
    },
    async downloadAttachment({ dispatch }, payload) {
      try {
        const { invoiceId, attachmentId } = payload;
        const url = `/invoice/${invoiceId}/attachment/${attachmentId}/download-file`;
        const res = await api(url, {
          method: 'GET',
          responseType: 'blob',
        })
        if (res?.status === 200) {
          return res.data
        }
        console.error('Request failed with status:', res.status);
        dispatch('handleFailure')
        return null
      } catch (e) {
        console.error('Error downloading attachment in Invoice Store.', e)
        dispatch('handleFailure')
        return null;
      }
    }
  },
  mutations: {
    setSelectedInvoice(state, payload) {
      state.selectedInvoice = payload
    },
  },
})

export default invoice
