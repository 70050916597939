<template>
  <v-app>
    <huboo-navbar
      @toggle-nav="$store.commit('core/setNavDrawerOpen', !navDrawerOpen)"
      @icon-clicked="iconClicked"
      :icons="icons || defaultIcons"
      :links="navbarLinks || defaultNavbarLinks"
    ></huboo-navbar>
    <huboo-nav-drawer
      @input="$store.commit('core/setNavDrawerOpen', $event)"
      :open="navDrawerOpen"
      :links="navDrawerLinks"
      :backLink="backLink"
    ></huboo-nav-drawer>
    <v-main>
      <huboo-errors></huboo-errors>
      <huboo-snack-stack></huboo-snack-stack>
      <keep-alive :include="keepAlive">
        <huboo-loading-screen v-if="loading"></huboo-loading-screen>
        <router-view v-else class="viewContainer" id="routerViewport"></router-view>
      </keep-alive>
    </v-main>
    <huboo-settings-drawer
      :value="settingsDrawerOpen"
      @input="settingsDrawerOpen = $event"
    ></huboo-settings-drawer>
  </v-app>
</template>

<script>
import icons from '@/layouts/defaults/icons'
import Navbar from '@/components/nav/Navbar'
import navbarLinks from '@/layouts/defaults/navbarLinks'
import NavDrawer from '@/components/nav/NavDrawer'

export default {
  name: 'default-layout',
  components: {
    'huboo-navbar': Navbar,
    'huboo-nav-drawer': NavDrawer,
  },
  props: {
    backLink: Object,
    icons: Array,
    keepAlive: { default: () => [], type: Array },
    loading: Boolean,
    navbarLinks: Array,
    navDrawerLinks: { default: () => [], type: Array },
  },
  data: () => ({
    settingsDrawerOpen: false,
  }),
  computed: {
    defaultIcons: () => icons,
    defaultNavbarLinks: () => navbarLinks,
    navDrawerOpen() {
      return this.$store.getters['core/getNavDrawerOpen']
    },
  },
  methods: {
    iconClicked(e) {
      if (e?.icon === 'settings') this.settingsClicked()
    },
    settingsClicked() {
      this.settingsDrawerOpen = !this.settingsDrawerOpen
    },
  },
}
</script>
