import i18n from '@/boot/i18n'

export default [
  {
    url: '/dashboard',
    text: i18n.t('ui.dashboard'),
  },
  {
    url: '/billing',
    text: i18n.t('ui.billing'),
  },
  // {
  //   url: '/invoicing',
  //   text: i18n.t('ui.invoicing'),
  // },
]
