export default {
  ui: {
    accountDetails: 'Account details',
    billing: 'Billing',
    billingApp: 'Billing app',
    changeProfilePicture: 'Change profile picture',
    clearFilters: 'Clear filters',
    clickToSortByX: 'Click to sort by {x}',
    closeDrawer: 'Close drawer',
    currentlyUnavailable: 'Currently unavailable',
    dashboard: 'Dashboard',
    dragYourXHereToUploadIt: 'Drag your {x} here to upload it',
    fileSizeTooBig: 'File size too big',
    footer: 'Footer',
    home: 'Home',
    invoicing: 'Invoicing',
    itemsPerPage: 'Items per page',
    noFileSelected: 'No file selected',
    noResultsToShow: 'No results to show',
    noXProvided: 'No {x} provided',
    orClickToBrowse: 'Or click to browse',
    pleaseSeletFromTheFollowing: 'Please selet from the following',
    pleaseSelectAnX: 'Please select an {x}',
    pleaseSelectAX: 'Please select a {x}',
    pleaseSelectNXOnly: 'Please select {n} {x} only',
    profilePicture: 'Profile picture',
    resultsPerPage: 'Results per page',
    selectX: 'Select {x}',
    selectADifferentX: 'Select a different {x}',
    theXHasBeenDeleted: 'The {x} has been deleted',
    uploading: 'Uploading',
    uploadNewX: 'Upload new {x}',
    uploadX: 'Upload {x}',
    userProfile: 'User profile',
    version: 'version',
    xMissing: '{x} missing',
    ctas: {
      clearFilters: {
        text: 'Clear Filters',
      },
      applyFilters: {
        text: 'Apply Filters',
      },
    },
    filtersMenu: {
      title: 'Table filters',
      activeFiltersText: 'active filters',
    },
    priceListVersion: 'Price List Version',
  },
}
