import api from '@/boot/api'
import i18n from '@/boot/i18n'
import cloneDeep from 'lodash/cloneDeep'
import upperFirst from 'lodash/upperFirst'
import router from '@/boot/router'
import swal from 'sweetalert'
import Vue from 'vue'
import { getErrorMessage, makeDefaultGetters, makeDefaultMutations, setState } from '@/store/shared'
import { HTTP } from '@/utilities/constants'

const { ERRORS, NON_ERRORS, NON_GLOBAL_ERRORS } = HTTP.STATUSES

const defaultState = () => ({
  activeCurrencyPrefix: '£',
  apiEndpointsLoading: {},
  apiToken: null,
  authUser: null,
  csrfToken: '',
  layout: 'loading-layout',
  errors: {},
  keepAlive: [],
  navDrawerOpen: true,
  routerViewportOffsetTop: 0,
  snackbarCount: 0,
  snackStack: [],
  tables: {
    itemsPerPage: 10,
  },
  uploadProgresses: [], // expects objects with url [string] and progress [integer] properties
})

const properties = Object.keys(defaultState())

const defaultGetters = makeDefaultGetters(properties)

const defaultMutations = makeDefaultMutations(properties, defaultState())

const state = defaultState()

const getters = {
  ...defaultGetters,
  // TODO: I'm hardcoding passport for now,
  // but we should aim to handle multiple auth types, e.g., token, etc.
  getAuthType: () => 'passport',
  // TODO: implement locale generated currency functions
  activeCurrencyPrefix: state => state.activeCurrencyPrefix,
  apiEndpointIsLoading: state => ({ method, url }) => !!state.apiEndpointsLoading[method]?.[url],
  authUser: state => cloneDeep(state.authUser?.data),
  getIsAdmin: state => cloneDeep(state.authUser?.data.isAdmin),
  hasErrors: state => !!Object.keys(state.errors).length,
  globalErrors: state => {
    const errors = cloneDeep(state.errors)
    return Object.keys(errors)
      .map(n => Number(n))
      .filter(s => ERRORS.filter(e => !NON_GLOBAL_ERRORS.includes(e)).includes(s))
      .map(e => String(e) + ': ' + (errors[e]?.message || i18n.t('common.error')))
  },
  getSnackStack: state => cloneDeep(state.snackStack),
  uploadProgressByUrl: state => url => state.uploadProgresses.find(up => up.url === url)?.progress,
  validationError: state => error => state.errors[422]?.errors?.[error],
  validationErrors: state => state.errors[422]?.errors,
}

const actions = {
  async fetchApiToken(...[, { email, password }]) {
    const res = await api('/user/login', { auth: true, data: { email, password }, method: 'POST' })
    return res?.data
  },
  async fetchAuthUser({ state }) {
    if (!state.apiToken) return
    const res = await api('/auth-user')
    return res?.data
  },
  forceLogout(...[, { dangerMode, flash, icon, text, title, timeout = 5000 }]) {
    const loc = '/login?flash_message=' + flash
    const goToLoginScreen = () => {
      window.location = loc
    }
    swal({ dangerMode, icon, text, title, timeout }).then(() => {
      goToLoginScreen()
    })
  },
  handleAjaxError({ commit, dispatch }, { data = {}, status } = {}) {
    if (!status || NON_ERRORS.includes(status)) return
    const { error, errors, message = getErrorMessage(status) } = data
    // not authenticated, force logout
    if (status === 401 || status === 419)
      dispatch('forceLogout', {
        dangerMode: true,
        flash: i18n.t('auth.yourSessionHasExpired'),
        icon: 'warning',
        text: i18n.t('auth.unfortunatelyTheSystemCouldNotAuthenticateYou'),
        title: i18n.t('auth.youAreBeingLoggedOut'),
      })
    commit('setErrors', { error, errors, message, status })
  },
  handleApiError({ dispatch }, e) {
    dispatch('handleAjaxError', e)
  },
  handleRouteChange({ commit }, { to, from }) {
    const l = to?.meta?.layout
    const old = from?.meta?.layout
    if (l && l !== old) commit('setLayout', l)
  },
  async logIn({ commit, dispatch }, payload = {}) {
    const token = await dispatch('fetchApiToken', payload)
    if (!token) return
    commit('setApiToken', token)
    const refreshed = await dispatch('refreshApiToken')
    if (!refreshed) return
    commit('setApiToken', refreshed)
    const authUser = await dispatch('fetchAuthUser')
    if (!authUser) return
    commit('setAuthUser', authUser)
    dispatch('redirect', '/dashboard')
  },
  async logOut({ commit, dispatch }, { flash } = {}) {
    const res = await api('/user/logout', { method: 'POST' })
    if (res?.status == 204) {
      dispatch('redirect', '/login', { params: { flash } })
      commit('resetAuthUser')
      commit('resetApiToken')
    }
  },
  onInitialLoad({ commit }, { initialStateData }) {
    const resetOnLoad = [
      'apiEndpointsLoading',
      'errors',
      'snackStack',
      'snackbarCount',
      'uploadProgresses',
    ]
    resetOnLoad.forEach(k => {
      commit('reset' + upperFirst(k))
    })
    commit('loadInitialStateData', initialStateData)
  },
  redirect({ commit }, url) {
    commit('resetErrors')
    router.push(url)
  },
  async refreshApiToken({ state }) {
    const refreshToken = state.apiToken?.refreshToken
    if (!refreshToken) return
    const res = await api('/user/refresh-token', { data: { refreshToken }, method: 'POST' })
    return res?.data
  },
}

const mutations = {
  ...defaultMutations,
  launchSnackbar(state, snackbar) {
    state.snackbarCount++
    const stack = cloneDeep(state.snackStack)
    stack.push({ ...snackbar, id: state.snackbarCount })
    Vue.set(state, 'snackStack', stack)
  },
  loadInitialStateData(state, data) {
    if (!data || typeof data !== 'object') return
    const allowed = ['apiToken', 'authUser']
    Object.keys(data).forEach(k => {
      if (allowed.includes(k)) Vue.set(state, k, cloneDeep(data[k]))
    })
  },
  resetState: (state, replacement) => {
    setState(state, replacement || defaultState())
  },
  removeSnackbar(state, id) {
    const stack = cloneDeep(state.snackStack)
    const index = stack.findIndex(i => i.id === id)
    if (index !== -1) stack.splice(index, 1)
    Vue.set(state, 'snackStack', stack)
  },
  setApiEndpointsLoading(state, { method, url, value }) {
    const target = state.apiEndpointsLoading
    if (!target[method]) Vue.set(target, method, {})
    Vue.set(target[method], url, value)
  },
  setErrors(state, { status, message, error, errors }) {
    // TODO: currently the API is inconsistent - sometimes 'error' will be sent, other times
    // 'errors' will be sent - I believe 'errors' being used consistently would be better,
    // even if it only contains one item
    Vue.set(state.errors, status, { error, errors, message })
  },
  removeError(state, fieldName) {
    if (!state.errors[422]) return
    if (state.errors[422].errors[fieldName]) Vue.set(state.errors[422].errors, fieldName, [])
  },
  setUploadProgress(state, { url, progress }) {
    const target = state.uploadProgresses.find(v => v.url === url)
    if (target) target.progress = progress
    else state.uploadProgresses.push({ url, progress })
  },
}

const core = {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
}

export default core
