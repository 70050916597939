import {
  faChevronDown as faChevronDownReg,
  faInfoCircle as faInfoCircleReg,
} from '@fortawesome/pro-regular-svg-icons'

import {
  faCaretDown as faCaretDownSolid,
  faChevronLeft as ChevronLeftSolid,
  faChevronRight as ChevronRighttSolid,
  faSortUp as faSortUpSolid,
  faBars as faBarsSolid,
  faTimesCircle as faTimesCircleSolid,
} from '@fortawesome/pro-solid-svg-icons'

export const billingIcons = {
  light: [],
  regular: [faChevronDownReg, faInfoCircleReg],
  solid: [
    faCaretDownSolid,
    ChevronLeftSolid,
    ChevronRighttSolid,
    faSortUpSolid,
    faBarsSolid,
    faTimesCircleSolid,
  ],
}
