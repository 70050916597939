import api from '@/boot/api'
import i18n from '@/boot/i18n'

const state = () => ({
  failedOrders: { data: [] },
  reprocessData: [],
})

const getters = {
  getFailedOrders: state => state.failedOrders,
  getReprocessData: state => state.reprocessData,
}

const actions = {
  async fetchFailedOrders({ commit }, payload) {
    const response = await api('/failed-orders', payload)
    if (response.data) {
      commit('setFailedOrders', response.data)
    }
  },

  handleSuccess({ commit }) {
    const message = i18n.t('common.successfulSnackMessage')
    commit('core/launchSnackbar', { color: 'success', message }, { root: true })
  },

  handleFailure({ commit }) {
    const message = i18n.t('common.failureSnackMessage')
    commit('core/launchSnackbar', { color: 'error', message }, { root: true })
  },

  async reprocessFailedOrders({ commit, dispatch }, payload) {
    const response = await api('/reprocess-failed-orders', payload, { method: 'POST' })
    if (response?.status === 201) {
      commit('clearFailedOrders')
      commit('setReprocessData', response.data)
      dispatch('handleSuccess')
      return true
    }
    dispatch('handleFailure')
    return false
  },
}
const mutations = {
  setFailedOrders(state, payload) {
    Object.assign(state.failedOrders, payload)
  },
  clearFailedOrders: state => {
    state.failedOrders.data = []
  },
  setReprocessData(state, payload) {
    state.reprocessData = payload
  },
}

const failedOrders = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
}

export default failedOrders
