<template>
  <v-select
    :label="$t('ui.priceListVersion')"
    :value="getSelectedPriceListVersionId"
    :items="priceListVersionOptions"
    @input="setSelectedPriceListVersionId"
    @click="onPriceListVersionClick"
    dense
    rounded
    outlined
    :loading="isLoading"
    hide-details="auto"
    ref="price-list-version-select"
  />
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import sortBy from 'lodash/sortBy'

export default {
  name: 'PriceListVersionSelect',

  data() {
    return { isLoading: false }
  },

  computed: {
    ...mapGetters('billing', ['getSelectedPriceListVersionId', 'getPriceListVersions']),

    priceListVersionOptions() {
      return sortBy(this.getPriceListVersions.data, plv => -plv.id).map(priceListVersion => ({
        value: priceListVersion.id,
        text:
          priceListVersion.name +
          (priceListVersion.isDefault ? ` (${this.$t('common.defaultLower')})` : ''),
      }))
    },
  },

  async mounted() {
    this.isLoading = true
    await this.fetchPriceListVersions()
    this.isLoading = false
  },

  methods: {
    ...mapActions('billing', ['fetchPriceListVersions']),
    ...mapMutations('billing', ['setSelectedPriceListVersionId']),

    async onPriceListVersionClick() {
      this.isLoading = true
      await this.fetchPriceListVersions({ force: true })
      this.isLoading = false
    },
  },
}
</script>
