import createCrudStore from '@/store/shared/storeFactory'

const createBillingStoreDispatcher = dispatch => (action, payload) =>
  dispatch('billing/' + action, payload, { root: true })

const fulfilmentPricing = createCrudStore('/fulfilment-price-list/', {
  state: {
    priceListRegions: [],
    exportUrl: '',
    links: {},
    meta: {},
  },
  getters: {
    getRegions: state => state.priceListRegions,
    getExportUrl: state => state.exportUrl,
    getMeta: state => state.meta,
  },
  actions: {
    async removeFulfilmentPriceListsByHubooBox({ dispatch, rootGetters }, { params } = {}) {
      const dispatchToBillingStore = createBillingStoreDispatcher(dispatch)

      const { hubooBox } = rootGetters['billing/getSelectedBox']

      const res = await dispatchToBillingStore('send', {
        url: `/huboo-box/${hubooBox}/fulfilment-price-list`,
        method: 'DELETE',
        params,
      })

      if (res?.status === 204) {
        return dispatchToBillingStore('handleRemoveSuccess', { target: 'fulfilmentPriceLists' })
      }

      dispatchToBillingStore('handleRemoveFailure', { target: 'fulfilmentPriceLists' })
    },
  },
})

export default fulfilmentPricing
