const customStoragePricingRoute = {
  component: () =>
    import(
      /* webpackChunkName: "CustomStoragePricing" */ '@/modules/CustomStoragePricing/CustomStoragePricing.vue'
    ),
  meta: { layout: 'huboo-box-layout' },
  name: 'CustomStoragePricing',
  path: '/billing/huboo_boxes/:id/custom_storage_pricing',
}

export default customStoragePricingRoute
