const defaultPickRateNumberPricingRoute = {
  component: () =>
    import(
      /* webpackChunkName: "DefaultPickRateNumberPricing" */ '@/modules/DefaultPickRateNumberPricing/DefaultPickRateNumberPricing.vue'
    ),
  meta: { layout: 'huboo-box-layout' },
  name: 'DefaultPickRateNumberPricing',
  path: '/billing/default_pick_rate_number_pricing',
}

export default defaultPickRateNumberPricingRoute
