<template>
  <v-radio-group
    @change="onChange"
    @input="onInput"
    :column="column"
    :disabled="disabled"
    :error-messages="errorMessages"
    :label="label"
    :mandatory="mandatory"
    :name="name"
    :row="row"
    :value="value"
  >
    <template v-slot:label>
      <slot name="label"></slot>
    </template>
    <v-radio v-for="option in options" :key="option.value" v-bind="option"></v-radio>
  </v-radio-group>
</template>

<script>
export default {
  name: 'HubooRadio',
  props: {
    column: Boolean,
    disabled: Boolean,
    errorMessages: Array,
    label: String,
    mandatory: Boolean,
    name: String,
    options: { required: true, type: Array },
    row: Boolean,
    rules: { default: () => [], type: Array },
    value: [Boolean, Number, String],
  },
  data: () => ({}),
  watch: {
    options: {
      immediate: true,
      handler() {
        this.validateOptions()
      },
    },
  },
  methods: {
    onChange(e) {
      this.$emit('input', e)
    },
    onInput(e) {
      this.$emit('input', e)
    },
    validateOptions() {
      this.options.forEach(o => {
        if (!Object.prototype.hasOwnProperty.call(o, 'value'))
          console.warn('Huboo Radio Options MUST declare a value', o)
      })
    },
  },
}
</script>
