<template>
  <v-toolbar-items class="navbarLinks">
    <v-btn
      class="navbarLink"
      text
      :to="link.url"
      v-for="(link, index) in links"
      :key="'navbarLink' + index"
      >{{ link.text | title }}</v-btn
    >
  </v-toolbar-items>
</template>

<script>
export default {
  name: 'NavbarLinks',
  props: {
    links: { type: Array, required: true },
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.navbarLinks {
  position: relative;
}
</style>
