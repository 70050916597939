export default {
  auth: {
    emailAndPasswordDoNotMatchOurRecords: 'Email and password do not match our records',
    forgottenYourPassword: 'Forgotten your password?',
    forgottenYourUsername: 'Forgotten your username?',
    helpUsFindYourUsername:
      'Please provide the details requested in the form below to help us find your username.',
    helpUsResetYourPassword: 'Please provide us with your username to help us reset your password.',
    login: 'Login',
    logIn: 'Log in',
    logout: 'Logout',
    logOut: 'Log out',
    password: 'Password',
    passwordReset: 'Password reset',
    pleaseEnterThePrimaryEmailAddress: 'Please enter the primary email address for your account.',
    pleaseEnterYourUsername: 'Please enter your username...',
    pleaseEnterYourPassword: 'Please enter your password...',
    primaryEmailAddress: 'Primary Email Address',
    unfortunatelyTheSystemCouldNotAuthenticateYou:
      'Unfortunately the system could not authenticate you.  This is usually because your session has expired.  Please log in again to continue.',
    username: 'Username',
    usernameReminder: 'Username reminder',
    youAreBeingLoggedOut: 'You are being logged out.',
    yourSessionHasExpired: 'Your session has expired.  Please log in again.',
  },
}
