<template>
  <v-list-item :to="url" link>
    <v-list-item-icon v-if="icon">
      <v-icon>{{ icon }}</v-icon>
    </v-list-item-icon>
    <v-list-item-title>{{ text | title }}</v-list-item-title>
  </v-list-item>
</template>

<script>
export default {
  name: 'NavLink',
  props: {
    icon: String,
    text: String,
    url: String,
  },
  methods: {},
}
</script>
<style lang="scss" scoped>
.v-list-item--active {
  color: white !important;
}

.v-list-item:after {
  display: none;
}
</style>
