<template>
  <v-snackbar
    bottom
    class="pa-2"
    :color="color"
    @input="close"
    left
    shaped
    :timeout="timeout"
    :value="visible"
    :vertical="vertical"
  >
    <span>{{ message }}</span>
    <template v-slot:action="{ attrs }">
      <v-btn class="mr-1" icon :color="buttonColor" text v-bind="attrs" @click="close">
        <v-icon>close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'huboo-snackbar',
  props: {
    snackbar: { type: Object, required: true },
  },
  data: () => ({}),
  computed: {
    buttonColor() {
      return this.snackbar?.buttonColor
    },
    color() {
      return this.snackbar?.color
    },
    message() {
      return this.snackbar?.message
    },
    timeout() {
      return this.snackbar?.timeout
    },
    vertical() {
      return this.snackbar?.vertical
    },
    visible() {
      return this.snackbar?.visible
    },
  },
  methods: {
    close() {
      this.$store.dispatch('core/closeSnackbar', this.snackbar)
    },
  },
}
</script>
