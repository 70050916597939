export const freeze = v => Object.freeze(v)

export const deepFreeze = v => {
  if (Array.isArray(v)) v.forEach(i => deepFreeze(i))
  else if (typeof v === 'object') Object.keys(v).forEach(i => deepFreeze(v[i]))
  return freeze(v)
}

export const ACCOUNT_PROTECTION_TYPES = deepFreeze({ FULL: 'FULL', HALF: 'HALF' })

export const BILLING_TYPES = deepFreeze({ MULTI_ITEM: 'MI', PICK_RATE: 'PR' })

export const STORAGE_TYPES = deepFreeze({
  ITEM_STORAGE: 'ITEM',
  REPLEN_STORAGE: 'REPLEN',
  LEGACY_STORAGE: 'LEGACY',
})

export const CASIFY = deepFreeze({ ACCEPTED_CASES: ['camel', 'snake'] })

export const CHECKBOX_VALUES = deepFreeze({
  CHECKBOX_FALSE: 'check_box_outline_blank',
  CHECKBOX_TRUE: 'check_box',
})

export const CONSOLE_MESSAGE_TYPES = deepFreeze([
  'assert',
  'clear',
  'count',
  'countReset',
  'debug',
  'dir',
  'dirxml',
  'error',
  'group',
  'groupCollapsed',
  'groupEnd',
  'info',
  'log',
  'profile',
  'profileEnd',
  'table',
  'time',
  'timeEnd',
  'timeLog',
  'timeStamp',
  'trace',
  'warn',
])

export const HTTP = deepFreeze({
  STATUSES: {
    ERRORS: [
      ...[400, 401, 402, 403, 404, 405, 406, 407, 408, 409, 410, 411, 412, 413, 414, 415, 416, 417],
      419,
      ...[421, 422, 423, 424, 425, 426, 427, 428, 429, 430, 431],
      451,
      ...[500, 501, 502, 503, 504, 505, 506, 507, 508, 509, 510, 511],
    ],
    NON_GLOBAL_ERRORS: [401, 419, 422],
    NON_ERRORS: [
      ...[100, 101, 102, 103],
      ...[200, 201, 202, 203, 204, 205, 206, 207, 208],
      226,
      ...[300, 301, 302, 303, 304, 305, 306, 307, 308],
    ],
  },
})

export const PICK_RATE_TYPES = deepFreeze({ CONTAINER: 1, PICK_NUMBER: 2 })

export const SNACKBAR_DEFAULTS = deepFreeze({
  buttonColor: 'indigo',
  closeButton: true,
  color: 'primary',
  message: '',
  timeout: 9000,
  vertical: false,
  visible: false,
})

export const SUPPORTED_INPUT_TYPES = deepFreeze(['input', 'textarea', 'select', 'v-text-field'])

export const TRUTHY = deepFreeze(['true', 'TRUE', true, 1])

export const CSV_PRICING_UPLOAD_TYPES = deepFreeze({
  FULFILMENT: 'FULFILMENT',
})
