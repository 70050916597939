const defaultPackagingPricingRoute = {
  component: () =>
    import(
      /* webpackChunkName: "DefaultPackagingPricing" */ '@/modules/DefaultPackagingPricing/DefaultPackagingPricing.vue'
    ),
  meta: { layout: 'huboo-box-layout' },
  name: 'DefaultPackagingPricing',
  path: '/billing/default_packaging_pricing',
}

export default defaultPackagingPricingRoute
