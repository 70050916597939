import billing from '@/store/modules/billing'
import core from '@/store/modules/core'
import invoicing from '@/store/modules/invoicing'
import failedOrders from '@/modules/FailedOrders/failedOrders.store'
import customSubscriptionPricing from '@/modules/CustomSubscriptionPricing/customSubscriptionPricing.store'
import defaultSubscriptionPricing from '@/modules/DefaultSubscriptionPricing/defaultSubscriptionPricing.store'
import customStoragePricing from '@/modules/CustomStoragePricing/customStoragePricing.store'
import defaultStoragePricing from '@/modules/DefaultStoragePricing/defaultStoragePricing.store'
import defaultPackagingPricing from '@/modules/DefaultPackagingPricing/defaultPackagingPricing.store'
import defaultManualPricing from '@/modules/DefaultManualPricing/defaultManualPricing.store'
import defaultPickRateNumberPricing from '@/modules/DefaultPickRateNumberPricing/defaultPickRateNumberPricing.store'
import defaultPickRateContainerPricing from '@/modules/DefaultPickRateContainerPricing/defaultPickRateContainerPricing.store'
import fulfilmentDefaultPricing from '@/modules/FulfilmentDefaultPricing/fulfilmentDefaultPricing.store'
import skuSpecificPricing from '@/modules/SkuSpecificPricing/skuSpecificPricing.store'
import fulfilmentPricing from '@/modules/CustomFulfilmentPricing/fulfilmentPricing.store'

const storeConfig = {
  modules: {
    billing,
    core,
    invoicing,
    failedOrders,
    customSubscriptionPricing,
    defaultSubscriptionPricing,
    customStoragePricing,
    defaultStoragePricing,
    defaultPackagingPricing,
    defaultPickRateContainerPricing,
    defaultManualPricing,
    defaultPickRateNumberPricing,
    fulfilmentDefaultPricing,
    skuSpecificPricing,
    fulfilmentPricing,
  },
}

export default storeConfig
