import createCrudStore from '@/store/shared/storeFactory'

const defaultPackagingPricing = createCrudStore('/billing-default-price-list/', {
  state: {
    defaultPriceListRegions: [],
    exportUrl: '',
    links: {},
    meta: {},
  },
  getters: {
    getRegions: state => state.defaultPriceListRegions,
    getExportUrl: state => state.exportUrl,
    getMeta: state => state.meta,
  },
})

export default defaultPackagingPricing
