<template>
  <v-navigation-drawer app right @input="$emit('input', $event)" :value="value" temporary>
    <v-list dense>
      <v-list-item link dense @click="$emit('input', false)" class="text-right">
        <v-list-item-content></v-list-item-content>
        <v-list-item-icon>
          <v-icon :title="$t('common.close')">close</v-icon>
        </v-list-item-icon>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
          <v-icon>settings</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('common.settings') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-list dense>
      <v-list-item v-for="item in items" :key="item.title" link>
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <template v-slot:append>
      <v-list class="pa-0 appVersion">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Version: {{ version }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list class="pa-0" color="primary" dark>
        <v-list-item link @click="logOut" class="logoutButton">
          <v-list-item-icon>
            <huboo-loading size="24" v-if="loggingOut"></huboo-loading>
            <v-icon v-else>logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('auth.logOut') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script>
import env from '@/boot/env'

export default {
  name: 'HubooSettingsDrawer',
  components: {},
  props: {
    value: Boolean,
  },
  data: () => ({
    version: env.VERSION,
  }),
  computed: {
    items() {
      return [
        {
          title: 'Setting (Turned On)',
          icon: 'check_box',
        },
        {
          title: 'Setting (Turned Off)',
          icon: 'check_box_outline_blank',
        },
      ]
    },
    loggingOut() {
      return this.$store.getters['core/apiEndpointIsLoading']({
        url: '/user/logout',
        method: 'POST',
      })
    },
  },
  methods: {
    logOut() {
      this.$store.dispatch('core/logOut')
    },
  },
}
</script>

<style lang="scss" scoped></style>
