<template>
  <v-navigation-drawer
    class="billing-nav"
    :value="open"
    @input="$emit('input', $event)"
    app
    dark
    :src="navImage"
  >
    <template v-if="showNavImage" v-slot:img="{ props, src }">
      <v-img
        v-bind="{ props, src }"
        height="100%"
        gradient="to top right, rgba(17,168,120,.4), rgba(0,0,0,.8)"
      >
      </v-img>
    </template>

    <v-list class="userDetailsContainer" v-if="authUser">
      <v-list-item class="px-2">
        <v-list-item-avatar color="primary" class="avatarImage" size="60">
          <v-img v-if="authUser.profilePictureUrl" :src="authUser.profilePictureUrl"></v-img>
          <span v-else class="white--text headline">{{
            authUser.name || '?' | initials | noSpaces
          }}</span>
        </v-list-item-avatar>
      </v-list-item>
      <v-list-item link>
        <v-list-item-content>
          <v-list-item-title class="title">
            {{ authUser.name }}
          </v-list-item-title>
          <v-list-item-subtitle>{{ authUser.email }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <nav-link-list :backLink="backLink" :links="links"></nav-link-list>
  </v-navigation-drawer>
</template>

<script>
import NavLinkList from '@/components/nav/NavLinkList'

export default {
  name: 'NavDrawer',
  components: {
    'nav-link-list': NavLinkList,
  },
  props: {
    backLink: Object,
    open: Boolean,
    links: { default: () => [], type: Array },
  },
  data: () => ({}),
  computed: {
    authUser() {
      return this.$store.getters['core/authUser']
    },
    navImage() {
      return this.showNavImage ? 'https://picsum.photos/1920/1080?random&navDrawer' : ''
    },
    showNavImage() {
      return this.$env.SHOW_NAV_DRAWER_IMAGE
    },
  },
}
</script>

<style lang="scss" scoped>
.billing-nav {
  background-color: var(--v-primary-base) !important;
  box-shadow: inset -10px 0px 100px #00000055;
}
.avatarImage {
  border: #02da9466 solid 1px;
}
.drawerOverlay {
  height: 100%;
  opacity: 0.3;
}
</style>

<style lang="scss">
// Fighting Vuetify specificity.
.billing-nav {
  .v-list-item--active {
    .v-icon {
      color: white !important;
    }
  }
}
</style>
