const defaultPickRateContainerPricingRoute = {
  component: () =>
    import(
      /* webpackChunkName: "DefaultPickRateContainerPricing" */ '@/modules/DefaultPickRateContainerPricing/DefaultPickRateContainerPricing.vue'
    ),
  meta: { layout: 'huboo-box-layout' },
  name: 'DefaultPickRateContainerPricing',
  path: '/billing/default_pick_rate_container_pricing',
}

export default defaultPickRateContainerPricingRoute
