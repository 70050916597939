<template>
  <v-app>
    <v-main>
      <huboo-errors></huboo-errors>
      <huboo-snack-stack></huboo-snack-stack>
      <huboo-page full-height>
        <div class="logoContainer">
          <huboo-logo></huboo-logo>
        </div>
        <p class="error--text">{{ $t('errors.sorrySomethingWentWrong') }}</p>
      </huboo-page>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'disabled-layout',
  components: {},
  props: {},
  data: () => ({}),
  computed: {},
  created() {},
  methods: {},
}
</script>

<style lang="scss" scoped>
.logoContainer {
  max-width: 300px;
  margin: auto;
}
</style>
