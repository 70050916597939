import createCrudStore from '@/store/shared/storeFactory'
import api from '@/boot/api'
import { format } from 'date-fns'

const xeroInvoicing = createCrudStore('/xero-invoicing/', {
  state: {
    exportUrl: '',
    meta: {},
    xeroData: {
      data: [],
      health: {
        data: {},
        timeStamp: '',
      },
    },
  },
  getters: {
    getXeroData: state => state.xeroData,
  },
  actions: {
    async healthCheck({ commit }) {
      const res = await api('/auth/xero/health-check/', {
        method: 'GET',
      })
      if (res.data) {
        commit('setXeroHealth', res.data)
      }
      return res
    },
    clearHealthData({ commit }) {
      commit('clearXeroHealth')
    },
    async generateInvoices({ dispatch }, payload = {}) {
      try {
        const res = await api('/invoice/generate', payload, {
          method: 'POST',
        })
        if (res?.status === 200) {
          dispatch('handleSuccess')
        } else {
          dispatch('handleFailure')
        }
        return res
      } catch (error) {
        dispatch('handleFailure')
        console.error('Failed to generate invoices:', error)
      }
    },
  },
  mutations: {
    setXeroHealth(state, payload) {
      Object.assign(state.xeroData.health, {
        data: payload,
        timeStamp: format(new Date(), 'dd-MM-yyyy_HH:mm:ss'),
      })
    },
    clearXeroHealth(state) {
      state.xeroData.health = {
        data: {},
        timeStamp: '',
      }
    },
  },
})

export default xeroInvoicing
